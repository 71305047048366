import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';


const PdfSkeleton = () => {
  return (
    <Box sx={{ padding: 2, position: 'relative' }}>
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          zIndex: 10,
          backgroundColor: 'rgba(255, 255, 255, 0.8)',
          padding: 2,
          borderRadius: 1,
        }}
      >
        <Stack direction='row' gap={1}>
          <Typography component="div">Please wait, the PDF is being generated...</Typography>
          <CircularProgress style={{ height: '30px', width: '30px' }} />
        </Stack>
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', marginBottom: 2 }}>
        <Skeleton height={40} variant="text" width="100%" />
      </Box>
      <Skeleton height={400} variant="rectangular" width="100%" />
      <Box sx={{ display: 'flex', justifyContent: 'space-between', marginTop: 2 }}>
        <Skeleton height={30} variant="text" width="100%" />
        <Skeleton height={30} variant="text" width="100%" />
      </Box>
    </Box>
  );
};

export default PdfSkeleton