import ganttStore from './ganttStore';

export function addBaselineLayer(ganttId) {
  const gantt = ganttId ? ganttStore.getGantt(ganttId) : this;
  gantt.attachEvent('onTaskLoading', function (task) {
    task.baseline_start = task.baseline_start
      ? task.baseline_start instanceof Date
        ? task.baseline_start
        : gantt.date.parseDate(task.baseline_start, '%Y-%m-%d')
      : undefined;
    task.baseline_end = task.baseline_end
      ? task.baseline_end instanceof Date
        ? task.baseline_end
        : gantt.date.parseDate(task.baseline_end, '%Y-%m-%d')
      : undefined;
    return true;
  });

  return gantt.addTaskLayer({
    id: 'baselineLayer',
    renderer: {
      render: (task) => {
        if (task.baseline_start && task.baseline_end) {
          const sizes = gantt.getTaskPosition(task, task.baseline_start, task.baseline_end);
          const el = document.createElement('div');
          el.className = 'baseline';
          el.style.left = sizes.left + 3 + 'px';
          el.style.width = sizes.width - 6 + 'px';
          el.style.height = sizes.height / 2 - 2 + 'px';
          el.style.top = sizes.top + 9 + 'px';

          return el;
        }
        return false;
      },
      getRectangle: function (task, view) {
        return gantt.getTaskPosition(task, task.baseline_start, task.baseline_end);
      },
    },
  });
}
