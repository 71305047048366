const { isSameDay } = require('date-fns');
const { resetTaskState, hasChild, getTaskTags, getChildren } = require('../tasks');
const { hasCircularDependency } = require('./hasCircularDependencies');
const { updateCriticalPath } = require('./updateCriticalPath');
const { updateFloat } = require('./updateFloat');
const { updateForecastedDates } = require('./updateForecastedDates');
const { updateAllTaskProperties } = require('./updateTaskProperties');

const updatedDateTaskIdsSet = new Set();
const taskCache = {};

const taskSchedulerEvents = {
  onAfterAutoSchedule: function (taskId, updatedTaskIds) {
    const gantt = this;
    const projectId = taskId ? gantt.getTask(taskId).project_id : undefined;
    const _updatedTaskIds = taskId ? [taskId, ...updatedTaskIds] : updatedTaskIds;
    if (_updatedTaskIds.length > 0) {
      // Auto schedule again to see if forecasted dates pushed other tasks
      gantt.autoSchedule();
    } else {
      const updatedDateTaskIds = [...updatedDateTaskIdsSet];
      updatedDateTaskIdsSet.clear();
      gantt.callEvent('onAfterForecastedUpdate', [updatedDateTaskIds]);
      const updatedPropertiesTaskIds = updateAllTaskProperties(projectId, false, gantt);
      const updatedFloatTaskIds = updateFloat(projectId, false, gantt);
      const updatedCriticalPathTaskIds = updateCriticalPath(projectId, false, gantt);
      const allUpdateTaskIdsSet = new Set([
        ...updatedDateTaskIds,
        ...updatedPropertiesTaskIds,
        ...updatedFloatTaskIds,
        ...updatedCriticalPathTaskIds,
      ]);
      const allUpdatedTaskIds = [...allUpdateTaskIdsSet];
      // Update all the tasks
      if (allUpdatedTaskIds.length) {
        gantt.batchUpdate(() => {
          allUpdatedTaskIds.forEach((taskId) => {
            if (gantt.ext.undo && Object.hasOwn(taskCache, taskId)) {
              const task = gantt.getTask(taskId);
              const updatedTask = gantt.copy(task);
              Object.assign(task, taskCache[taskId]);
              if (gantt?.ext?.undo) {
                gantt.ext.undo.saveState(taskId, gantt.config.undo_types.task);
              }
              Object.assign(task, updatedTask);
            }
            gantt.updateTask(taskId);
          });
        });
      }
      Object.keys(taskCache).forEach((taskId) => delete taskCache[taskId]);
      gantt._isAutoScheduling = false;
      gantt.callEvent('onFinishAutoSchedule', [allUpdatedTaskIds]);
    }
  },
  onBeforeAutoSchedule: function (taskId) {
    const gantt = this;

    if (gantt._block_auto_schedule) {
      return false;
    }

    if (!gantt._isAutoScheduling) {
      gantt._isAutoScheduling = true;
      const projectId = taskId && gantt.getTask(taskId)?.project_id;

      if (
        hasCircularDependency(
          gantt.getTaskBy((task) => (projectId ? task.project_id === projectId : true)),
          gantt.getLinks(),
          taskId
        )
      ) {
        console.error('Circular dependencies found. Blocking autoschedule');
        gantt.callEvent('onAutoScheduleCircularLink', []);
        return false;
      } else {
        /* Save the task state before updating changing updates.
       This is not optimized so we need to see what kind of performance hit it causes.
        Otherwise we would need to refactor how we handle updating forecasted dates and the task properties */
        if (gantt.ext.undo) {
          gantt.eachTask(function (task) {
            if (Number(task.id) !== Number(taskId)) {
              taskCache[task.id] = gantt.copy(task);
              if (gantt?.ext?.undo) {
                gantt.ext.undo.saveState(task.id, gantt.config.undo_types.task);
              }
            }
          });
        }
      }
    }

    // Make sure all forecasted dates are updated before autoscheduling
    updateForecastedDates(taskId ? [taskId] : undefined, gantt).forEach((taskId) =>
      updatedDateTaskIdsSet.add(parseFloat(taskId))
    );

    return true;
  },
  onBeforeTaskDrag: function (id, mode, e) {
    taskCache[id] = this.copy(this.getTask(id));
    return true;
  },

  onBeforeTaskUpdate: function (id, task) {
    if (task.status === 'complete' || task.type === 'project_bar') {
      return true;
    }
    const oldEndDate = task.end_date;
    const newEndDate =
      task.autoschedule_date === 'schedule' ? task.scheduled_end_date : task.forecasted_end_date;
    if (!isSameDay(oldEndDate, newEndDate)) {
      task.end_date = newEndDate;
      // Need to update the task duration because that is what the auto scheduler uses to generate its plan
      task.duration = this.calculateDuration(task);
    }
    return true;
  },

  onBeforeTaskAutoSchedule: function (task, newStartDate) {
    // Only move todo tasks
    if (task.status === 'todo' && !task?.dates_locked_by) {
      return true;
    } else {
      return false;
    }
  },

  onAfterTaskAutoSchedule: function (task, start, link, predecessor) {
    // Move parent tasks scheduled date
    if (hasChild(task.id, this)) {
      task.scheduled_end_date = this.calculateEndDate({
        start_date: start,
        duration: task.work_days,
        task: task,
      });
      if (task.autoschedule_date === 'schedule') {
        task.scheduled_end_date = task.end_date;
      } else {
        task.forecasted_end_date = task.end_date;
      }
    } else if (task.status === 'todo') {
      // Sync task dates
      task.forecasted_start_date = task.start_date;
      task.scheduled_end_date = task.end_date;
      task.forecasted_end_date = task.end_date;
    }
  },

  onBeforeTaskMove: function (id, parentId, tindex) {
    const task = this.getTask(id);
    const parentTask = this.getTask(parentId);

    if (task?.project_id !== parentTask?.project_id) {
      return false;
    }
    if (parentId != this.config.root_id) {
      task.$old_parent = task.parent;
      return true;
    } else {
      return false;
    }
  },

  onAfterTaskMove: function (id, parentId, tindex) {
    const gantt = this;
    const task = this.getTask(id);

    if (gantt.isTaskExists(task.$old_parent)) {
      const oldParent = this.getTask(task.$old_parent);
      this.updateTask(task.id);
      // Reset the $old_parent's state so that it is now todo
      if (!hasChild(task.$old_parent, gantt)) {
        resetTaskState(oldParent);
      }

      gantt.batchUpdate(() => {
        updateForecastedDates([task.$old_parent], gantt).forEach((taskId) =>
          gantt.updateTask(taskId)
        );
      }, false);
    }

    task.tags = getTaskTags(task.id, gantt);
    const children = getChildren(task.id, gantt);
    if (children?.length) {
      gantt.batchUpdate(() => {
        children.forEach((task) => {
          const tags = getTaskTags(task, gantt);
          task.tags = tags;
          gantt.refreshTask(task.id);
        });
      });
    }

    gantt.autoSchedule(id);
  },

  onAfterTaskAdd: function (id, task) {
    if (task.type !== 'placeholder' && this.isTaskExists(task.parent) && !task.$virtual) {
      task.tags = getTaskTags(task.id, this);
      this.autoSchedule(id);
    }
  },

  onAfterTaskDelete: function (id, task) {
    task.$deleted = true;
    // If the task exists and the parent is not a 'project_bar' (a task with an id less than 0), then we check to see if it is a parent or if it has children, and update its state accordingly
    if (this.isTaskExists(task.parent) && task.parent > 0) {
      const parentTask = this.getTask(task.parent);

      // If the task does not have a child, reset the parentTask (so it is no longer active), and autoSchedule the parent
      if (!hasChild(parentTask.id, this)) {
        resetTaskState(parentTask);
        this.updateTask(parentTask.id);
      }

      // Update parent forecasted dates
      this.autoSchedule(parentTask.id);
    }
  },

  onAfterLinkDelete: function (id, link) {
    // if (this.isTaskExists(link.source)) {
    //   this.autoSchedule(link.source);
    // }
  },
};

module.exports = { taskSchedulerEvents };
