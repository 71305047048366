import { Grid } from '@mui/material';
import { FormLabel } from 'assets/style-components/typography';
import { WorkDaysComponent } from 'components/common/v3/WorkDaysComponent';
import { Controller, useForm } from 'react-hook-form';
import { useDebounceCallback } from 'usehooks-ts';

const defaultValues = {
  workday_calendar: {
    friday: '1',
    monday: '1',
    sunday: '0',
    tuesday: '1',
    saturday: '0',
    thursday: '1',
    wednesday: '1',
  },
};

export const ScheduleForm = ({ onSubmit, initialData, canEdit }) => {
  const { control, handleSubmit } = useForm({
    defaultValues,
    values: initialData,
  });

  const debouncedSaveFormState = useDebounceCallback(() => {
    handleSubmit(onSubmit)();
  });

  return (
    <form>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <FormLabel>WORK DAYS</FormLabel>
          <Controller
            control={control}
            name="workday_calendar"
            rules={{ onChange: debouncedSaveFormState }}
            render={({ field: { value, onChange }, fieldState: { error } }) => (
              <WorkDaysComponent disabled={!canEdit} workdayCalendar={value} onChange={onChange} />
            )}
          />
        </Grid>
      </Grid>
    </form>
  );
};
