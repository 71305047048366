import { Edit } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import EditPredecessorDialog from 'components/projectOverview/gantt/components/dialog/EditPredecessorDialog';
import { useSyncDependenciesMutation } from 'features/dependencies/api/dependency.api';
import { handleSyncDependency } from 'features/drawers/components/SelectDependency';
import { useState } from 'react';

export const PredecessorsCell = ({ gantt, task, children }) => {
  const [isPredecessorDialogOpen, setIsPredecessorDialogOpen] = useState(false);
  const [syncDependencies, { isLoading: isSyncingDependencies }] = useSyncDependenciesMutation();
  const disabled = task.readonly === true || gantt.config.readonly;

  const tasks = gantt.getTaskBy('project_id', task.project_id);

  const handleUpdate = async (selectedDependencies) => {
    const response = await syncDependencies({
      dependencies: selectedDependencies,
      taskId: task.id,
    }).unwrap();

    handleSyncDependency(gantt, response);
  }

  return (
    <div className={`predecessor-column ${disabled ? 'cell_disabled' : ''}`}>
      <span className="predecessor-label">{children}</span>
      <span className="edit-span">
        <IconButton
          size="small"
          sx={{ p: 0 }}
          onClick={(event) => {
            event.stopPropagation();
            setIsPredecessorDialogOpen(true);
          }}
        >
          <Edit color={'inherit'} />
        </IconButton>
        {isPredecessorDialogOpen && (
          <EditPredecessorDialog
            gantt={gantt}
            handleClose={() => setIsPredecessorDialogOpen(false)}
            handleUpdate={handleUpdate}
            isLoading={isSyncingDependencies}
            open={isPredecessorDialogOpen}
            taskId={task.id}
            tasks={tasks}
          />
        )}
      </span>
    </div>
  );
};
