import React from 'react';
import { GanttStatic } from '@blackhyve/dhtmlx-gantt';
import { DemandCalculation } from 'features/resources/api/resources.models';
import { Tooltip } from '@mui/material';

export function DemandColumnCell({ task, gantt }: { task: any; gantt: GanttStatic }) {
  const isOverMaxDemand =
    task.demand_calculation === DemandCalculation.Max ? task.$demand > task.demand : false;
  const excess = ((task.$demand || 0) - (task.demand || 0)).toLocaleString();
  const sumOfChildrenLabel = task.$demand ? task.$demand.toLocaleString() : '0';
  const taskDemandLabel = task.demand ? task.demand.toLocaleString() : '';
  const demandUnits = 'hours';

  const demandLabel =
    task.demand_calculation === DemandCalculation.Max
      ? `${sumOfChildrenLabel} (limit ${taskDemandLabel})`
      : taskDemandLabel;
  return (
    <div
      style={{
        display: 'flex',
        width: '100%',
        alignItems: 'center',
        color: isOverMaxDemand ? 'red' : undefined,
      }}
    >
      <Tooltip
        arrow
        title={isOverMaxDemand ? `Demand limit exceeded by ${excess} ${demandUnits}` : ''}
      >
        <div style={{ flex: 1 }}>{demandLabel}</div>
      </Tooltip>
    </div>
  );
}
