import { ContentLayout } from 'components/layouts';
import BackendProvider, { useBackend } from '../components/BackendProvider';
import GoodDataDashboard from '../components/GoodDataDashboard';
import { SecondaryNav } from 'components/navigation/SecondaryNav';
import DashboardSelectionMenu from '../components/DashboardSelectionMenu';
import { selectCurrentUserWorkspaceId } from 'features/auth';
import { useGetDashboardsQuery } from '../api/analytics';
import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { CrewSizeGraph } from '../components/CrewSizeGraph';

const blackHyveDashboards = [
  {
    dashboard_id: 'crew-size-graph',
    dashboard_name: 'Crew Size Graph',
    global: false,
  },
];

export const AnalyticsWindow = ({ isGlobal }) => {
  const backend = useBackend();
  const workspaceId = useSelector((state) => selectCurrentUserWorkspaceId(state));
  const { data: dashboardData, isLoading } = useGetDashboardsQuery({ workspaceId });
  const [analyticsDashboardId, setAnalyticsDashboardId] = useState('crew-size-graph');
  const [analyticsWorkspaceId, setAnalyticsWorkspaceId] = useState(null);

  const dashboards = useMemo(() => {
    const filteredDashboards =
      dashboardData?.filter((dashboard) => Boolean(dashboard.global) === Boolean(isGlobal)) || [];

    return [...blackHyveDashboards, ...filteredDashboards];
  }, [dashboardData, isGlobal]);

  useEffect(() => {
    if (dashboardData?.length) {
      setAnalyticsDashboardId(dashboardData[0].dashboard_id);
      setAnalyticsWorkspaceId(dashboardData[0].workspace_id);
    }
  }, [dashboardData]);

  if (!isLoading) {
    return (
      <ContentLayout>
        <SecondaryNav>
          <DashboardSelectionMenu
            dashboardId={analyticsDashboardId}
            dashboards={dashboards}
            label="Select Dashboard"
            setDashboardId={setAnalyticsDashboardId}
          />
        </SecondaryNav>
        {analyticsDashboardId === 'crew-size-graph' ? (
          <CrewSizeGraph />
        ) : (
          <BackendProvider backend={backend}>
            <GoodDataDashboard
              dashboardId={analyticsDashboardId}
              isGlobal={isGlobal}
              workspaceId={analyticsWorkspaceId}
            />
          </BackendProvider>
        )}
      </ContentLayout>
    );
  }
};
