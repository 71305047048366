import {
  Autocomplete,
  Chip,
  CircularProgress,
  Grid,
  ListItem,
  ListItemText,
  TextField,
} from '@mui/material';
import { FormTextField } from 'assets/style-components/textfield';
import { FormLabel } from 'assets/style-components/typography';
import { selectCurrentUser } from 'features/auth';
import { useGetFieldValueQuery } from 'features/labels/api/fieldValues.api';
import { Label } from 'features/labels/components/Label';
import { useGetWorkspaceCompanyQuery } from 'features/projectCompanies/api/projectCompanies.api';
import { useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useDebounceCallback } from 'usehooks-ts';

const defaultValues = {
  project_manager: null,
  project_number: '',
  client: '',
  project_dimension_1: null,
  project_dimension_2: null,
  project_dimension_3: null,
};

export const ProjectDetailsForm = ({ onSubmit, initialData, canEdit, projectId }) => {
  const { control, handleSubmit } = useForm({
    defaultValues,
    values: initialData,
  });

  const currentUser = useSelector(selectCurrentUser);
  const { data: userCompany = { users: [] } } = useGetWorkspaceCompanyQuery(
    { projectId },
    { skip: projectId === undefined }
  );

  const users = useMemo(() => {
    const users =
      currentUser.role === 'super_admin'
        ? [{ ...currentUser, isCurrentUser: true }, ...userCompany.users]
        : [...userCompany.users];
    return users;
  }, [userCompany, currentUser]);

  const debouncedSaveFormState = useDebounceCallback(() => {
    handleSubmit(onSubmit)();
  });

  return (
    <form>
      <Grid container spacing={2}>
        {projectId && (
          <Grid item xs={12}>
            <FormLabel>PROJECT MANAGER</FormLabel>
            <Controller
              control={control}
              name="project_manager"
              rules={{ onChange: debouncedSaveFormState }}
              render={({ field }) => (
                <Autocomplete
                  {...field}
                  fullWidth
                  selectOnFocus
                  getOptionKey={(option) => option.id}
                  isOptionEqualToValue={(option, value) => option.id == value}
                  options={users}
                  getOptionLabel={(option) => {
                    const user = option?.id ? option : users.find((user) => user.id == option);
                    return user?.first_name + ' ' + user?.last_name;
                  }}
                  renderInput={(params) => (
                    <TextField {...params} placeholder="Select User" size="small" />
                  )}
                  renderOption={(props, option, { selected }) => (
                    <ListItem
                      {...props}
                      disablePadding
                      key={option.id}
                      secondaryAction={
                        option.isCurrentUser ? (
                          <Chip color="secondary" label="Logged in" size="small" />
                        ) : null
                      }
                    >
                      <ListItemText primary={`${option.first_name} ${option.last_name}`} />
                    </ListItem>
                  )}
                  onChange={(event, newValue) => {
                    field.onChange(newValue?.id || null);
                  }}
                />
              )}
            />
          </Grid>
        )}

        <Grid item xs={6}>
          <FormLabel>PROJECT NUMBER</FormLabel>
          <Controller
            control={control}
            name="project_number"
            rules={{ onChange: debouncedSaveFormState }}
            render={({ field }) => (
              <FormTextField
                {...field}
                fullWidth
                disabled={!canEdit}
                placeholder="Project Number"
                size="small"
                variant="outlined"
              />
            )}
          />
        </Grid>

        <Grid item xs={6}>
          <FormLabel>CLIENT</FormLabel>
          <Controller
            control={control}
            name="client"
            rules={{ onChange: debouncedSaveFormState }}
            render={({ field }) => (
              <FormTextField
                {...field}
                fullWidth
                disabled={!canEdit}
                placeholder="Client"
                size="small"
                variant="outlined"
              />
            )}
          />
        </Grid>

        <Grid item xs={12}>
          <FormLabel>
            <Label labelKey="project_dimension_1" />
          </FormLabel>
          <Controller
            control={control}
            name="project_dimension_1"
            rules={{ onChange: debouncedSaveFormState }}
            render={({ field: { value, onChange } }) => (
              <AsyncAutocomplete name="project_dimension_1" value={value} onChange={onChange} />
            )}
          />
        </Grid>

        <Grid item xs={6}>
          <FormLabel>
            <Label labelKey="project_dimension_2" />
          </FormLabel>
          <Controller
            control={control}
            name="project_dimension_2"
            rules={{ onChange: debouncedSaveFormState }}
            render={({ field: { value, onChange } }) => (
              <AsyncAutocomplete name="project_dimension_2" value={value} onChange={onChange} />
            )}
          />
        </Grid>

        <Grid item xs={6}>
          <FormLabel>
            <Label labelKey="project_dimension_3" />
          </FormLabel>
          <Controller
            control={control}
            name="project_dimension_3"
            rules={{ onChange: debouncedSaveFormState }}
            render={({ field: { value, onChange } }) => (
              <AsyncAutocomplete name="project_dimension_3" value={value} onChange={onChange} />
            )}
          />
        </Grid>
      </Grid>
    </form>
  );
};

const AsyncAutocomplete = ({ name, value, onChange }) => {
  const { data = [], isLoading } = useGetFieldValueQuery(name);

  return (
    <Autocomplete
      getOptionLabel={(option) => option}
      isOptionEqualToValue={(option, value) => option === value}
      loading={isLoading}
      options={data}
      value={value}
      renderInput={(params) => (
        <TextField
          {...params}
          fullWidth
          size="small"
          variant="outlined"
          slotProps={{
            input: {
              ...params.InputProps,
              endAdornment: (
                <>
                  {isLoading ? <CircularProgress color="inherit" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </>
              ),
            },
          }}
        />
      )}
      onChange={(event, newValue) => {
        onChange(newValue);
      }}
    />
  );
};
