import ganttStore from 'components/projectOverview/gantt/ganttConfig/ganttStore';

export function addForecastedDatesLayer(ganttId) {
  const gantt = ganttId ? ganttStore.getGantt(ganttId) : this;
  gantt.addTaskLayer({
    renderer: {
      render: function (task, view, config, pos) {
        const startDate = task.actual_start_date || task.forecasted_start_date;
        const endDate = task.actual_end_date || task.forecasted_end_date;
        // get pos of scheduled_end_date and end_date
        const sizes = gantt.getTaskPosition(task, startDate, endDate);
        var el = document.createElement('div');
        const isNegativePace = task.scheduled_end_date < endDate;
        el.className = 'targetEndDate ' + (isNegativePace ? 'orange' : 'green');
        el.style.left = sizes.left + 3 + 'px';
        el.style.width = sizes.width - 6 + 'px';
        el.style.top =
          sizes.top +
          (task.row_height || config.row_height) / 2 +
          (task.bar_height || config.bar_height) / 2 +
          'px';
        el.style.height = 5 + 'px';
        el.style.zIndex = 1;
        el.style.borderRadius = isNegativePace ? '2 0 0 2' : '0 2 2 0';
        el.dataset.id = task.id;
        return el;
      },
      getRectangle: function (task, view, resourceConfig, gantt) {
        if (task.status !== 'todo' || task.type === 'parent_task' || task.type === 'project_bar') {
          const startDate = task.actual_start_date || task.forecasted_start_date;
          const endDate = task.actual_end_date || task.forecasted_end_date;
          return gantt.getTaskPosition(task, startDate, endDate);
        } else {
          return false;
        }
      },
    },
    filter: (task) =>
      (task.type === 'task' && task.status !== 'todo') ||
      task.type === 'parent_task' ||
      task.type === 'project_bar',
  });
}
