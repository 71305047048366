import { Autocomplete, Grid, TextField } from '@mui/material';
import { FormTextField } from 'assets/style-components/textfield';
import { FormLabel } from 'assets/style-components/typography';
import { STATES } from 'helpers/constants';
import { Controller, useForm } from 'react-hook-form';
import { useDebounceCallback } from 'usehooks-ts';

const defaultValues = {
  address: '',
  city: '',
  state: null,
  zip_code: '',
};

export const LocationForm = ({ onSubmit, initialData, canEdit }) => {
  const { control, handleSubmit } = useForm({
    defaultValues,
    values: initialData,
  });

  const debouncedSaveFormState = useDebounceCallback(() => {
    handleSubmit(onSubmit)();
  });

  return (
    <form>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <FormLabel>STREET</FormLabel>
          <Controller
            control={control}
            name="address"
            rules={{ onChange: debouncedSaveFormState }}
            render={({ field }) => (
              <FormTextField
                {...field}
                fullWidth
                disabled={!canEdit}
                placeholder="Street Address"
                size="small"
                variant="outlined"
              />
            )}
          />
        </Grid>

        <Grid item sm={6} xs={12}>
          <FormLabel>CITY</FormLabel>
          <Controller
            control={control}
            name="city"
            rules={{ onChange: debouncedSaveFormState }}
            render={({ field }) => (
              <FormTextField
                {...field}
                fullWidth
                disabled={!canEdit}
                placeholder="Project City"
                size="small"
                variant="outlined"
              />
            )}
          />
        </Grid>

        <Grid item sm={6} xs={12}>
          <FormLabel>STATE</FormLabel>
          <Controller
            control={control}
            name="state"
            rules={{ onChange: debouncedSaveFormState }}
            render={({ field: { value, onChange } }) => (
              <Autocomplete
                autoComplete
                autoHighlight
                disabled={!canEdit}
                options={STATES}
                size="small"
                value={value}
                variant="outlined"
                renderInput={(params) => (
                  <TextField {...params} placeholder="Project State" variant="outlined" />
                )}
                onChange={(event, newValue) => {
                  onChange(newValue);
                }}
              />
            )}
          />
        </Grid>

        <Grid item xs={12}>
          <FormLabel>ZIP CODE</FormLabel>
          <Controller
            control={control}
            name="zip_code"
            rules={{ onChange: debouncedSaveFormState }}
            render={({ field }) => (
              <FormTextField
                {...field}
                fullWidth
                disabled={!canEdit}
                placeholder="Project Zip Code"
                size="small"
                variant="outlined"
              />
            )}
          />
        </Grid>
      </Grid>
    </form>
  );
};
