import React, { useState } from 'react';
import ganttStore from '../../ganttStore';
import { GanttStatic } from '@blackhyve/dhtmlx-gantt';
import {
  Button,
  Checkbox,
  DialogActions,
  FormControlLabel,
  Popover,
  TextField,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { DemandCalculation } from 'features/resources/api/resources.models';

export function loadDemandMenu(ganttId: number) {
  const gantt: GanttStatic = ganttStore.getGantt(ganttId);

  gantt.config.editor_types.demand = {
    show: function (id, _column, _config, placeholder) {
      const task = gantt.getTask(id);
      gantt.config.external_render.renderElement(
        <DemandMenuPopover
          anchorEl={placeholder}
          gantt={gantt}
          initialDemand={task.demand}
          initialDemandCalculation={task.demand_calculation}
          key={`${task.id}-demand-menu`}
          taskId={task.id}
          onClose={(demandState: DemandMenuPopoverState) => {
            const { demand, demandCalculation } = demandState;
            task.demand = demand;
            task.demand_calculation = demandCalculation;
            gantt.updateTask(id);
            gantt.ext.inlineEditors.save();
          }}
        />,
        placeholder
      );
    },
    hide: function () {},

    get_value: function () {},
    set_value: function () {},

    is_changed: function () {
      return true;
    },
    is_valid: function () {
      return true;
    },
    save: function () {},
    focus: function () {},
  };
}

const useStyles = makeStyles(() => ({
  paper: {
    width: 350,
    overflowY: 'auto',
    borderRadius: '8px',
    padding: '10px 10px',
  },
}));

interface DemandMenuPopoverState {
  demand: number | null;
  demandCalculation: DemandCalculation | null;
}

function DemandMenuPopover({
  initialDemand,
  initialDemandCalculation,
  anchorEl,
  onClose,
  taskId,
  gantt,
}: any) {
  const classes = useStyles();
  const [open, setOpen] = useState(true);
  const initialDemandState = {
    demand: initialDemand || '',
    demandCalculation: initialDemandCalculation,
  };

  const [demandState, setDemandState] = useState<DemandMenuPopoverState>(initialDemandState);
  const hasChildren = !!gantt.hasChild(taskId);
  const task = gantt.getTask(taskId);

  function handleClose() {
    setOpen(false);
    onClose(demandState);
  }

  function handleCancel() {
    setOpen(false);
    setDemandState(initialDemandState);
    onClose(initialDemandState);
  }

  function parseDemandString(demand: string) {
    const parsed = parseFloat(demand);
    if (isNaN(parsed)) {
      return null;
    }

    return parsed;
  }

  function handleDemandStateChanged(changes: Partial<DemandMenuPopoverState>) {
    setDemandState((prev: DemandMenuPopoverState) => ({
      ...prev,
      ...changes,
    }));
  }

  function handleToggleBankOfHours(e) {
    const newDemandCalculation =
      demandState.demandCalculation === DemandCalculation.Max ? null : DemandCalculation.Max;
    handleDemandStateChanged({
      demand: initialDemand,
      demandCalculation: newDemandCalculation,
    });
  }

  function handleSelectSumOfChildren(e) {
    const newDemandCalculation =
      demandState.demandCalculation === DemandCalculation.Sum ? null : DemandCalculation.Sum;
    handleDemandStateChanged({
      demand: task.$demand || 0,
      demandCalculation: newDemandCalculation,
    });
  }

  return (
    <>
      {anchorEl && (
        <Popover
          anchorEl={anchorEl}
          classes={{ paper: classes.paper }}
          open={open}
          onClose={handleClose}
        >
          <TextField
            autoFocus
            fullWidth
            disabled={hasChildren && demandState.demandCalculation === DemandCalculation.Sum}
            id={'demand-input'}
            label="Demand"
            placeholder="Type in number"
            size="small"
            type="number"
            value={demandState.demand}
            onChange={(e) =>
              handleDemandStateChanged({
                demand: parseDemandString(e.target.value),
                demandCalculation: hasChildren ? demandState.demandCalculation : null,
              })
            }
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                e.preventDefault();
                handleClose();
              }
            }}
          />

          {hasChildren && (
            <>
              <FormControlLabel
                label="Limit subtask demand to this amount"
                control={
                  <Checkbox
                    checked={demandState.demandCalculation === DemandCalculation.Max}
                    inputProps={{ 'aria-label': 'controlled' }}
                    onChange={handleToggleBankOfHours}
                  />
                }
              />
              <FormControlLabel
                label="Use total from subtasks"
                control={
                  <Checkbox
                    checked={demandState.demandCalculation === DemandCalculation.Sum}
                    inputProps={{ 'aria-label': 'controlled' }}
                    onChange={handleSelectSumOfChildren}
                  />
                }
              />
            </>
          )}

          <DialogActions>
            <Button size="small" onClick={handleCancel}>
              Cancel
            </Button>

            <Button size="small" variant="contained" onClick={handleClose}>
              Update
            </Button>
          </DialogActions>
        </Popover>
      )}
    </>
  );
}
