const { parseDate } = require('../dates');
const { format, addDays } = require('date-fns');

function formatGanttTaskToTask(data, projectId) {
  const payload = {
    name: data.text,
    id: data.id,
    project_id: projectId ? projectId : data?.project_id,
    parent_id: data?.parent < 0 ? 0 : data.parent,
    imported_parent_id: data?.$imported_parent_id,

    scheduled_start: format(data.start_date, 'yyyy-MM-dd'),
    scheduled_end: format(addDays(data.scheduled_end_date || data.end_date, -1), 'yyyy-MM-dd'),

    forecasted_start: data.forecasted_start_date
      ? format(data.forecasted_start_date, 'yyyy-MM-dd')
      : null,
    forecasted_end: format(addDays(data.forecasted_end_date, -1), 'yyyy-MM-dd'),

    actual_start: data.actual_start_date ? format(data.actual_start_date, 'yyyy-MM-dd') : null,
    actual_end: data.actual_end_date
      ? format(addDays(data.actual_end_date, -1), 'yyyy-MM-dd')
      : null,

    autoschedule_date: data.autoschedule_date === 'schedule' ? 'schedule' : 'forecasted',
    demand: data.demand ? parseFloat(data.demand) : null,
    demand_calculation: data.demand_calculation || null,
    cal_days: data?.cal_days,
    duration_days: data?.work_days,
    duration_string: data.duration_string
      ? data.duration_string
      : data.duration_days + ' Work Days',

    percent_complete: data?.progress,
    crew_size: data?.crew_size,
    critical_path: data?.critical_path,

    effort_tag: data?.effort_tag,
    effort_hours: data?.effort_hours,

    free_float: data?.free_float,
    total_float: data?.total_float,

    area_id: data?.area_id,
    zone_id: data?.zone_id,
    location_id: data?.location_id,

    constraint_type: data?.constraint_type,
    constraint_date: data?.constraint_date ? format(data.constraint_date, 'yyyy-MM-dd') : '',

    target: data?.target,
    status: data?.status,

    is_parent: data.type === 'parent_task',
    is_lookahead: data?.is_lookahead ? data.is_lookahead : false,

    companies: data?.companies?.map(({ id }) => id),
    contacts: data?.contacts?.map(({ id }) => id),
    responsible_users: data?.responsible_users?.map(({ id }) => id),

    dates_locked_by: data?.dates_locked_by?.id || null,

    color: data?.task_color,
    trades: data?.trades?.length ? data?.trades : [],

    pace: data.pace,
    earned_days: data.earned_days,
    earned_planned_days: data.earned_planned_days,
    pr: data.pr,
    open_stuck_points_count: data.open_stuck_points_count,

    tags: data?.tags?.map((tag) => tag.id),
    procurements: data?.procurements?.map((procurement) => procurement.id),
    optimal_crew: data?.optimal_crew,
  };

  return payload;
}
function formatTaskToGanttTask(task) {
  const scheduledEnd = addDays(parseDate(task.scheduled_end), 1);
  const forecastedEnd = addDays(parseDate(task.forecasted_end), 1);

  const taskObj = {
    id: task.id,
    text: task.name,
    parent: task.parent_id,
    start_date: parseDate(task.scheduled_start),
    scheduled_end_date: scheduledEnd,
    forecasted_start_date: task?.forecasted_start
      ? parseDate(task.forecasted_start)
      : parseDate(task.scheduled_start),
    forecasted_end_date: addDays(parseDate(task.forecasted_end), 1),
    actual_start_date: task.actual_start ? parseDate(task.actual_start) : null,
    actual_end_date: task.actual_end ? addDays(parseDate(task.actual_end), 1) : null,
    end_date: task.autoschedule_date === 'schedule' ? scheduledEnd : forecastedEnd,
    work_days:
      task.duration_days !== undefined ? task.duration_days : parseInt(task.duration_string),
    cal_days: parseInt(task.cal_days),
    progress: parseFloat(task.percent_complete),
    baseline_start: task.baseline_start,
    uuid: task.uuid,
    baseline_end: task.baseline_end ? parseDate(task.baseline_end) : undefined,
    baseline_duration: task.baseline_duration,
    crew_size: task.crew_size,
    work_time: true,
    demand: task.demand ? parseFloat(task.demand) : null,
    demand_calculation: task.demand_calculation || null,
    duration_string: task.duration_string
      ? task.duration_string
      : task.duration_days + ' Work Days',
    effort_tag: task.effort_tag,
    location_id: task.location_id,
    zone_id: task.zone_id,
    area_id: task.area_id,
    effort_hours: task.effort_hours,
    shift: task.shift,
    workday_calendar: task.workday_calendar,
    pace: task.pace,
    earned_days: task.earned_days ? parseFloat(task.earned_days) : null,
    earned_planned_days: task.earned_planned_days ? parseFloat(task.earned_planned_days) : null,
    pr: task.pr ? parseFloat(task.pr) : null,
    latest_job_walk: task.latest_job_walk,
    project_id: task.project_id,
    status: task.status,
    critical_path: task.critical_path,
    children: task.children ? task.children : [],
    constraint_type: task.constraint_type ? task.constraint_type : 'ASAP',
    constraint_date: task.constraint_date ? parseDate(task.constraint_date) : '',
    free_float: task.free_float,
    total_float: task.total_float,
    type: task.is_parent ? 'parent_task' : 'task',

    is_lookahead: task?.is_lookahead,

    qty_type: task.qty_type,
    qty: task.qty,
    qty_per_day: task.production_rate,

    companies: task?.companies,
    contacts: task?.contacts,
    responsible_users: task?.responsible_users,

    dates_locked_by: task?.dates_locked_by,

    task_color: task?.color,
    trades: task?.trades?.length ? task?.trades : [],
    autoschedule_date: task.autoschedule_date === 'schedule' ? 'schedule' : 'forecasted',
    open_stuck_points_count: task?.open_stuck_points_count,
    $display_order: task?.display_order,

    is_production: task?.is_production,

    tags: task?.tags || [],
    // $isAutoSchedulerByScheduleDate: isSameDay(scheduledEnd, autoScheduleDate),

    procurements: task?.procurements || [],
    optimal_crew: task?.optimal_crew,
  };
  return taskObj;
}

module.exports = { formatTaskToGanttTask, formatGanttTaskToTask };
