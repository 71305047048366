import Backdrop from '@mui/material/Backdrop';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import SelectDependency from 'features/drawers/components/SelectDependency';
import { memo, useState } from 'react';

const EditPredecessorDialog = ({ handleClose, taskId, open, gantt, tasks, handleUpdate, isLoading }) => {

  const task = gantt.getTask(taskId);
  const dependencies = task?.$target.map((linkId) => gantt.getLink(linkId));
  const [selectedDependencies, setSelectedDependencies] = useState(dependencies);

  return (
    <Dialog fullWidth={true} maxWidth={'sm'} open={open} onClose={handleClose}>
      <DialogTitle style={{ paddingBottom: '9px', paddingLeft: '20px' }}>
        Edit Predecessors
      </DialogTitle>
      <DialogContent style={{ overflow: 'hidden' }}>
        {isLoading && (
          <Backdrop open={true} style={{ zIndex: 13 }} sx={{ color: '#fff' }}>
            <CircularProgress className="loading" />
          </Backdrop>
        )}
        <SelectDependency
          allTasks={tasks}
          gantt={gantt}
          selectedDependencies={selectedDependencies}
          setSelectedDependencies={setSelectedDependencies}
          taskId={taskId}
        />
      </DialogContent>
      <DialogActions>
        <Button size="small" onClick={handleClose}>
          Cancel
        </Button>
        <Button size="small" variant="contained" onClick={() => {
          handleUpdate(selectedDependencies)
        }}>
          Update
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default memo(EditPredecessorDialog);
