/* eslint-disable no-inner-declarations */
/* eslint-disable no-undef */
import axios from 'axios';
import { Gantt as globalGantt } from '@blackhyve/dhtmlx-gantt';

export const configExportPlugin = (gantt, t) => {

  add_export_methods(gantt);

  if (globalGantt.plugin) {
    globalGantt.plugin(add_export_methods);
  }

};

/** Adding Export Method */
function add_export_methods(gantt) {

  gantt.exportToPDF = function (config) {
    var previousDateRage = null;

    if (config && config.raw) {
      if (config.start && config.end) {
        previousDateRage = {
          start_date: gantt.config.start_date,
          end_date: gantt.config.end_date,
        };
        gantt.config.start_date = gantt.date.str_to_date(gantt.config.date_format)(config.start);
        gantt.config.end_date = gantt.date.str_to_date(gantt.config.date_format)(config.end);
      }
      config = gantt.mixin(config, {
        name: "gantt." + '.pdf', data: gantt.ext.export_api._serializeHtml()
      });
    } else {
      config = gantt.mixin((config || {}), {
        name: "gantt." + '.pdf',
        data: this._serialize_all(),
        config: gantt.config
      });
      gantt.ext.export_api._fixColumns(config.config.columns);
    }
    config.version = gantt.version;
    const dataToExport = this._send_to_export(config, 'pdf');
    if (previousDateRage) {
      gantt.config.start_date = previousDateRage.start_date;
      gantt.config.end_date = previousDateRage.end_date;
    }
    // gantt.ext.export_api._sendToExport(config, type);
    return dataToExport
  };

  gantt._ajax_to_export = function (data, type, callback) {
    delete data.callback;
    const url = data.server || apiUrl;
    // const compressedData = pako.deflate(JSON.stringify(data));
    // const payload = JSON.stringify([{
    //   type,
    //   store: 1,
    //   data: compressedData
    // }])
    const payload = "type=" + type + "&store=1&data=" + encodeURIComponent(JSON.stringify(data));
    axios.post(url, payload).then((response) => {
      if (callback) {
        callback(response.data)
      }
    });
  };

  gantt._send_to_export = function (data, type) {
    var convert = gantt.date.date_to_str(gantt.config.date_format || gantt.config.xml_date);
    if (data.config) {
      data.config = gantt.copy(gantt.ext.export_api._serializableGanttConfig(data.config));
      gantt.ext.export_api._markColumns(data, type);
      if (data.config.start_date && data.config.end_date) {
        if (data.config.start_date instanceof Date) {
          data.config.start_date = convert(data.config.start_date);
        }
        if (data.config.end_date instanceof Date) {
          data.config.end_date = convert(data.config.end_date);
        }
      }
    }
    if (gantt.env.isNode) {
      var url = data.server || gantt.ext.export_api._apiUrl;
      var pack = {
        type: type,
        store: 0,
        data: JSON.stringify(data)
      };
      var callbackFunction = data.callback || function (response) {
        // tslint:disable-next-line no-console
      };
      return gantt.ext.export_api._xdr(url, pack, callbackFunction);
    }
    if (data.callback) {
      return gantt._ajax_to_export(data, type, data.callback);
    }
    var form = gantt.ext.export_api._createHiddenForm();
    form.firstChild.action = data.server || gantt.ext.export_api._apiUrl;
    form.firstChild.childNodes[0].value = JSON.stringify(data);
    form.firstChild.childNodes[1].value = type;
    form.firstChild.submit();
  };

  gantt._create_hidden_form = function () {
    if (!this._hidden_export_form) {
      const t = (this._hidden_export_form = document.createElement('div'));
      t.style.display = 'none';
      t.innerHTML =
        "<form method='POST' target='_blank'><textarea name='data' style='width:0px; height:0px;' readonly='true'></textarea><input type='hidden' name='type' value=''> </form>";
      document.body.appendChild(t);
    }
    return this._hidden_export_form;
  };


  /*=========================  NEW SERIALIZE =============================*/

  gantt._serialize_all = function () {
    const data = export_serialize();
    return data;
  };



  gantt._serialize = function serialize() {
    var tasks = [];
    var links = [];
    gantt.eachTask(function (obj) {
      if (obj.type !== "placeholder") {
        gantt.resetProjectDates(obj);
        tasks.push(this.serializeTask(obj));
      }
    }, gantt.config.root_id, this);

    var rawLinks = gantt.getLinks();
    for (var i = 0; i < rawLinks.length; i++) {
      links.push(this.serializeLink(rawLinks[i]));
    }

    const filteredTasks = tasks.filter((task) => task.isVisible).map((task) => {
      if (task.parent && gantt.isTaskExists(task.parent)) {
        const parent = gantt.getTask(task.parent);
        if (!parent.isVisible) {
          task.parent = null
        }
      }
      return task
    })

    console.log('PRINTING FILTERED TASKS =>', filteredTasks)

    return {
      data: filteredTasks,
      links: links
    };
  }

  gantt.serializeTask = function serializeTask(task) {
    return copy_object_all(task);
  }

  gantt.serializeLink = function serializeLink(link) {
    return this._copyLink(link);
  }

  gantt._copyLink = function _copyLink(obj) {
    var copy = {};
    for (var key in obj) {
      copy[key] = obj[key];
    }
    return copy;
  }

  function copy_object_all(obj) {
    let copy = gantt.ext.export_api._copyObjectBase(obj);
    const templates = [
      "leftside_text",
      "rightside_text",
      "task_text",
      "progress_text",
      "task_class"
    ];
    // serialize all text templates
    for (let i = 0; i < templates.length; i++) {
      let template = gantt.templates[templates[i]];
      if (template) {
        copy["$" + i] = template(obj.start_date, obj.end_date, obj);
      }
    }
    gantt.ext.export_api._copyObjectColumns(obj, copy);
    copy.open = obj.$open;
    return copy;
  }

  function export_serialize() {
    gantt.exportMode = true;
    const xmlFormat = gantt.templates.xml_format;
    const formatDate = gantt.templates.format_date;
    // use configuration date format for serialization so date could be parsed on the export
    // required when custom format date function is defined
    gantt.templates.xml_format = gantt.templates.format_date =
      gantt.date.date_to_str(
        gantt.config.date_format || gantt.config.xml_date
      );
    const data = gantt._serialize();
    gantt.templates.xml_format = xmlFormat;
    gantt.templates.format_date = formatDate;
    gantt.exportMode = false;
    return data;
  }

  /**=====  END serializeTask ===========*/
}


