import { api } from 'api';
import isEmpty from 'lodash/isEmpty';
import format from 'date-fns/format';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import addYears from 'date-fns/addYears';
import { useSelector } from 'react-redux';
import Button from '@mui/material/Button';
import { useEffect, useState } from 'react';
import { isValid, parseISO } from 'date-fns';
import { styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import Backdrop from '@mui/material/Backdrop';
import { useNavigate } from 'react-router-dom';
import { StyledDialog } from '@blackhyve/common';
import Typography from '@mui/material/Typography';
import { COLORS } from '../../../helpers/constants';
import { Controller, useForm } from 'react-hook-form';
import ColorPalate from 'components/common/ColorPalate';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { useCanEditProject } from 'hooks/useCanEditProject';
import CircularProgress from '@mui/material/CircularProgress';
import { selectCurrentUserWorkspaceId } from 'features/auth';
import { useCreateProjectMutation, useUpdateProjectMutation } from '../store/project.api';
import { FormLabel, HighlighterLinkLabel } from 'assets/style-components/typography';
import { PROJECT_STATUS, PROJECT_STATUSES } from '../api/constants';
import { Alert, MenuItem } from '@mui/material';

const initialState = {
  id: undefined,
  name: '',
  description: undefined,
  start_date: parseISO(format(new Date(), 'yyyy-MM-dd')),
  sub_completion_date: parseISO(format(addYears(new Date(), 1), 'yyyy-MM-dd')),
  est_completion_date: undefined,
  color: COLORS[0],
  status: PROJECT_STATUS.ACTIVE,
};

/**
 * Create or update project form
 * @param {String} id
 * @param {Boolean} open
 * @param {Object} handleClose
 * @returns
 */
const CreateProjectDialog = ({ id, open, handleClose }) => {
  const navigate = useNavigate();
  const [isDescriptionVisible, setIsDescriptionVisible] = useState(false);
  const canEdit = useCanEditProject(id);

  const [
    createProject,
    { isLoading: isProjectCreating, isError: isErrorCreating, error: errorCreating },
  ] = useCreateProjectMutation();
  const [updateProject, { isLoading: isProjectUpdating }] = useUpdateProjectMutation();
  const workspaceId = useSelector((state) => selectCurrentUserWorkspaceId(state));

  const { project: projectDetails, isLoading: isLoadingProject } =
    api.endpoints.getProjects.useQueryState(
      { workspaceId },
      {
        selectFromResult: ({ data: projects, isFetching, isLoading }) => {
          return {
            project: projects?.find((project) => project.id === id),
            isFetching,
            isLoading,
          };
        },
      }
    );

  const { control, handleSubmit, watch, setError, reset, setValue } = useForm({
    defaultValues: { ...initialState },
  });
  const startDate = watch('start_date');

  const handleToggleDescription = (event) => {
    event.preventDefault();
    canEdit && setIsDescriptionVisible(!isDescriptionVisible);
  };

  const handleSave = async (data) => {
    const requestParameter = {
      ...data,
      start_date: format(data.start_date, 'yyyy-MM-dd'),
      sub_completion_date: format(data.sub_completion_date, 'yyyy-MM-dd'),
      project_manager: data.pm_id,
      color: data.color?.value,
    };

    try {
      const handleApiError = (error) => {
        if (!isEmpty(error?.data?.errors)) {
          Object.keys(error?.data?.errors).forEach((field) => {
            setError(field, { message: error.data.errors[field] });
          });
        }
      };
      const handleApiSuccess = (success) => {
        handleClose();
        if (data.id === undefined)
          navigate(`/workspace/${workspaceId}/projects/${success?.data?.id}/planning-board`, {
            projectId: success?.data?.id,
          });
      };
      data.id
        ? updateProject(requestParameter).unwrap().then(handleApiSuccess).catch(handleApiError)
        : createProject(requestParameter).unwrap().then(handleApiSuccess).catch(handleApiError);
    } catch (error) {
      return setError(error.message);
    }
  };

  useEffect(() => {
    if (!isEmpty(projectDetails)) {
      reset({
        id: projectDetails.id,
        name: projectDetails.name,
        description: projectDetails.description,
        start_date: projectDetails.start_date
          ? parseISO(projectDetails.start_date)
          : initialState.start_date,
        sub_completion_date: projectDetails.sub_completion_date
          ? parseISO(projectDetails.sub_completion_date)
          : initialState.sub_completion_date,
        color: COLORS.find((color) => color.value === projectDetails.color),
        status: projectDetails.status,
      });
      if (projectDetails.description) {
        setIsDescriptionVisible(true);
      }
    }
  }, [projectDetails, reset]);

  return (
    <StyledDialog
      handleClose={handleClose}
      open={open}
      title={id ? 'Edit Project' : 'New Project'}
      actions={
        <>
          <Button size="small" onClick={handleClose}>
            Cancel
          </Button>
          <Button
            disabled={!canEdit}
            size="small"
            variant="contained"
            onClick={handleSubmit(handleSave)}
          >
            {id ? 'Update' : 'Create'}
          </Button>
        </>
      }
    >
      {isProjectCreating || isLoadingProject || isProjectUpdating ? (
        <Backdrop open={true} style={{ zIndex: 13 }} sx={{ color: '#fff' }}>
          <CircularProgress className="loading" />
        </Backdrop>
      ) : null}

      <form onSubmit={handleSubmit(handleSave)}>
        <Grid container spacing={2}>
          {isErrorCreating && (
            <Grid item xs={12}>
              <Alert severity={'error'}>{errorCreating?.data?.message}</Alert>
            </Grid>
          )}
          <Grid item xs={8}>
            <FormLabel>Project Name*</FormLabel>
            <Controller
              control={control}
              name="name"
              rules={{ required: 'Name field is required' }}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  autoFocus
                  fullWidth
                  disabled={!canEdit}
                  error={error}
                  helperText={error?.message}
                  placeholder={'Name'}
                  size="small"
                  type={'text'}
                  variant="outlined"
                />
              )}
            />
          </Grid>
          <Grid item xs={4}>
            <FormLabel>Status</FormLabel>
            <Controller
              control={control}
              name="status"
              render={({ field }) => (
                <TextField
                  select
                  size={'small'}
                  {...field}
                  fullWidth
                  disabled={!canEdit}
                  sx={{ textTransform: 'capitalize', minWidth: '150px' }}
                >
                  {PROJECT_STATUSES.map((option) => (
                    <MenuItem key={option} sx={{ textTransform: 'capitalize' }} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </TextField>
              )}
            />
          </Grid>
          <Grid item paddingTop={'0px !important'} xs={12}>
            <HighlighterLinkLabel>
              <Link
                component={'button'}
                disabled={!canEdit}
                type="button"
                onClick={handleToggleDescription}
              >
                {isDescriptionVisible ? 'Hide' : 'Add'} Description
              </Link>
            </HighlighterLinkLabel>
          </Grid>
          {isDescriptionVisible && (
            <Grid item xs={12}>
              <FormLabel>DESCRIPTION</FormLabel>
              <Controller
                control={control}
                name="description"
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    multiline
                    disabled={!canEdit}
                    placeholder="Description"
                    rows={5}
                    size="small"
                    variant="outlined"
                  />
                )}
              />
            </Grid>
          )}
          <Grid item xs={6}>
            <FormLabel>START DATE*</FormLabel>
            <Controller
              control={control}
              name="start_date"
              render={({ field: { ref, ...field }, fieldState: { error } }) => (
                <DatePicker
                  {...field}
                  disabled={!canEdit}
                  error={error ? true : false}
                  helperText={error?.message}
                  slotProps={{ textField: { size: 'small', fullWidth: true } }}
                />
              )}
              rules={{
                required: 'Project start date is required',
                validate: (value) => {
                  return isValid(value) || 'Invalid Date';
                },
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <FormLabel>SCHEDULED COMPLETION DATE*</FormLabel>
            <Controller
              control={control}
              name="sub_completion_date"
              render={({ field: { ref, ...field }, fieldState: { error } }) => (
                <DatePicker
                  {...field}
                  disabled={!canEdit}
                  error={error ? true : false}
                  helperText={error?.message}
                  slotProps={{ textField: { size: 'small', fullWidth: true } }}
                />
              )}
              rules={{
                required: 'Scheduled Completion date is required',
                validate: (value) =>
                  !isValid(value)
                    ? 'Invalid Date'
                    : value >= startDate ||
                      'Scheduled end date should be greater than project start date',
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <FormLabel>Color</FormLabel>
            <Controller
              control={control}
              name="color"
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <ColorInput defaultColor={value} disabled={!canEdit} setValue={setValue} />
              )}
            />
          </Grid>
        </Grid>
        <button hidden type="submit">
          Create
        </button>
      </form>
    </StyledDialog>
  );
};

export const ColorContainer = styled(Grid)(() => ({
  display: 'flex',
  cursor: 'pointer',
  paddingLeft: '10px',
  border: '1px solid #CECECE',
  height: 40,
  borderRadius: 2,
  alignItems: 'center',
  '&:hover': {
    border: '1px solid',
  },
}));

export const ColorCircle = styled(({ backgroundColor, ...props }) => <Grid {...props} />)(
  ({ backgroundColor }) => ({
    cursor: 'pointer',
    width: '20px',
    height: '20px',
    borderRadius: '20px',
    backgroundColor: backgroundColor,
    marginLeft: '4px',
  })
);

const ColorInput = ({ defaultColor, setValue, disabled }) => {
  const [openColorPalate, setOpenColorPalate] = useState(false);

  const handleSave = (selectedColor) => {
    const colorObj = COLORS.find((color) => color.value === selectedColor);
    setValue('color', colorObj);
    setOpenColorPalate(false);
  };

  const handleSelectColor = (color) => {
    setValue('color', color);
  };

  return (
    <ColorContainer container>
      <Grid container item xs={6}>
        <Typography style={{ fontSize: '13px' }}>{defaultColor?.name}</Typography>
        <ColorCircle backgroundColor={defaultColor?.value} />
      </Grid>
      <Grid container item style={{ justifyContent: 'flex-end', paddingRight: '5px' }} xs={6}>
        {COLORS.slice(0, 7).map((color, index) => (
          <ColorCircle
            backgroundColor={color.value}
            key={index}
            role={'button'}
            onClick={() => !disabled && handleSelectColor(color)}
          />
        ))}
        <Link
          component={'button'}
          style={{ marginLeft: '5px' }}
          type={'button'}
          onClick={(event) => {
            event.preventDefault();
            !disabled && setOpenColorPalate(event.target);
          }}
        >
          more
        </Link>
      </Grid>
      {openColorPalate && (
        <ColorPalate
          anchorEl={openColorPalate}
          handleClose={() => setOpenColorPalate(false)}
          handleSave={handleSave}
          open={Boolean(openColorPalate)}
        />
      )}
    </ColorContainer>
  );
};

export default CreateProjectDialog;
