import { Button, Tab, Tabs, Typography, useMediaQuery } from '@mui/material';
import Box from '@mui/system/Box';
import { useMemo } from 'react';
import { Link, NavLink, resolvePath, useLocation, useResolvedPath } from 'react-router-dom';

const useResolvedTabs = (tabs) => {
  let { pathname: basePathname } = useResolvedPath('');
  return useMemo(
    () =>
      tabs
        .filter((tab) => tab)
        .map((tab) => ({ ...tab, resolvedPath: resolvePath(tab.to, basePathname).pathname })),
    [basePathname, tabs]
  );
};

export const SecondaryNav = ({ title, tabs = [], children = undefined }) => {
  const resolvedTabs = useResolvedTabs(tabs);
  const isSmallDisplay = useMediaQuery((theme) => theme.breakpoints.down('md'));
  const { pathname } = useLocation();

  const currentTab = useMemo(
    () =>
      resolvedTabs.find((tab) =>
        tab.end ? pathname === tab.resolvedPath : pathname.startsWith(tab.resolvedPath)
      )?.to,
    [pathname, resolvedTabs]
  );

  return (
    <Box
      alignItems={'center'}
      bgcolor={'grey.50'}
      borderBottom={1}
      boxSizing={'border-box'}
      color={'grey.300'}
      columnGap={2}
      display={'flex'}
      flexWrap={'wrap'}
      pl={{ md: 3 }}
      pr={{ md: 3 }}
      width={1}
    >
      {title && (
        <Box
          boxSizing={'border-box'}
          color={'initial'}
          minWidth={{ xs: 1, md: 'unset' }}
          p={isSmallDisplay ? 0.5 : 1}
        >
          <Typography
            component={'div'}
            fontSize={{ xs: '1rem', sm: '1.3rem', md: '1.5rem' }}
            sx={{ fontWeight: 'bold', width: '100%' }}
            textAlign={isSmallDisplay ? 'center' : 'initial'}
            variant="h5"
          >
            {title}
          </Typography>
        </Box>
      )}
      {resolvedTabs.length !== 0 && currentTab !== undefined && (
        <Tabs
          allowScrollButtonsMobile
          scrollButtons="auto"
          value={currentTab}
          variant={'scrollable'}
          sx={{
            margin: isSmallDisplay && 'auto',
            minHeight: { xs: '30px', sm: '40px', md: '48px' },
            displayPrint: 'none',
          }}
        >
          {resolvedTabs.map(({ resolvedPath, end, ...tab }) => (
            <Tab
              component={Link}
              iconPosition={isSmallDisplay ? 'top' : 'start'}
              key={tab.to}
              value={tab.to}
              sx={{
                p: isSmallDisplay ? 0.5 : 1,
                flexGrow: isSmallDisplay ? '1' : 'unset',
                minHeight: { xs: '30px', sm: '40px', md: '48px' },
                fontSize: { xs: '.7rem', md: '.8rem' },
              }}
              {...tab}
            />
          ))}
        </Tabs>
      )}
      {children}
    </Box>
  );
};

export const SecondaryNavLink = ({ startIcon, children, ...props }) => {
  return (
    <Button
      size="small"
      variant="text"
      {...props}
      // end
      component={NavLink}
      startIcon={startIcon}
      sx={{
        color: 'grey.600',
        '&.active': {
          color: 'primary.main',
        },
      }}
    >
      {children}
    </Button>
  );
};
