import { api } from 'api';

export const analyticsAPI = api.injectEndpoints({
  endpoints: (build) => ({
    getJWT: build.query({
      query: ({ workspaceId }) => ({
        url: `/analytics/jwt`,
        method: 'GET',
      }),
      transformResponse: (response) => response.data.token,
    }),

    getDashboards: build.query({
      query: ({ workspaceId }) => ({
        url: `/analytics/dashboards`,
        method: 'GET',
      }),
      transformResponse: (response) => response.data,
    }),

    getCrewSize: build.query({
      query: ({ projectId, tagId, startDate, endDate }) => ({
        url: `/analytics/crew_size`,
        method: 'GET',
        params: {
          project: projectId,
          tag_id: tagId,
          date_range: startDate && endDate ? `${startDate}..${endDate}` : undefined,
        },
      }),
      transformResponse: (response) => ({
        scheduled: response.data.scheduled,
        actual: response.data.actual,
      }),
    }),
  }),
});

export const { useGetJWTQuery, useLazyGetJWTQuery, useGetDashboardsQuery, useGetCrewSizeQuery } =
  analyticsAPI;
