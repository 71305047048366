import { Bar } from 'react-chartjs-2';
import { useGetCrewSizeQuery } from '../api/analytics';
import { useParams } from 'react-router-dom';
import { Box, Button, ButtonGroup, Chip, CircularProgress, Typography } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import annotationPlugin from 'chartjs-plugin-annotation';
import { BarChart, Timeline } from '@mui/icons-material';
import AnalyticsTagFilter from './AnalyticsTagFilter';
import DateRangeSelector from './DateRangeSelector';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
  annotationPlugin
);

const generateColors = (count) => {
  const colors = [];
  const baseColors = [
    '#2563eb',
    '#16a34a',
    '#ea580c',
    '#9333ea',
    '#0891b2',
    '#be123c',
    '#84cc16',
    '#eab308',
    '#ec4899',
    '#6366f1',
  ];

  colors.push(...baseColors);

  if (count > baseColors.length) {
    const additionalCount = count - baseColors.length;
    for (let i = 0; i < additionalCount; i++) {
      // Use golden ratio to spread hues evenly
      const hue = (i * 137.508) % 360; // Golden angle approximation
      const saturation = 65 + (i % 20); // Vary saturation slightly
      const lightness = 45 + (i % 15); // Vary lightness slightly
      colors.push(`hsl(${hue}, ${saturation}%, ${lightness}%)`);
    }
  }

  return colors;
};

const getChartOptions = (dates) => {
  const options = {
    type: 'bar',
    responsive: true,
    maintainAspectRatio: false,
    interaction: {
      mode: 'index',
      intersect: false,
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: true,
        mode: 'nearest',
        intersect: true,
        backgroundColor: 'rgba(255, 255, 255, 0.95)',
        titleColor: '#333',
        titleFont: {
          size: 13,
          weight: 'normal',
        },
        bodyColor: '#666',
        bodyFont: {
          size: 12,
        },
        boxPadding: 12,
        borderColor: '#ddd',
        usePointStyle: true,
        borderWidth: 1,
        padding: 15,
        itemSort: () => -1,
        callbacks: {
          title: (tooltipItems) => {
            if (tooltipItems.length === 0) return '';
            return new Date(tooltipItems[0].label).toLocaleDateString();
          },
          label: (context) => {
            return `${context.dataset.label}: ${context.parsed.y} crew members`;
          },
          afterBody: () => null,
        },
      },
      annotation: {
        annotations: {},
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
        ticks: {
          maxRotation: 45,
          minRotation: 45,
          padding: 12,
          font: {
            size: 13,
          },
        },
      },
      y: {
        beginAtZero: true,
        border: {
          display: false,
        },
        grid: {
          color: '#e5e7eb',
        },
        ticks: {
          padding: 10,
          font: {
            size: 13,
          },
        },
      },
    },
  };

  // Add today line if today falls within the date range
  const today = new Date();
  today.setHours(0, 0, 0, 0);
  const todayStr = today.toLocaleDateString();

  if (dates.includes(todayStr)) {
    options.plugins.annotation.annotations.todayLine = {
      type: 'line',
      xMin: todayStr,
      xMax: todayStr,
      borderColor: '#1eb980',
      borderWidth: 2,
      borderDash: [6, 6],
    };
  }

  return options;
};

const createDatasetsByDate = (dataset, dates) => {
  if (!dataset || dataset.length === 0) return {};

  return dates.reduce((acc, date) => {
    const dataPoint = dataset.find(
      (item) => new Date(item.date.split('T')[0] + 'T12:00:00').toLocaleDateString() === date
    );
    acc[date] = dataPoint || null;
    return acc;
  }, {});
};

const createBaseDatasets = (crewSizeData, dates, companies) => {
  if (!crewSizeData?.scheduled.companies || !crewSizeData?.scheduled.dataset) {
    return {
      scheduledDatasets: [],
      actualDatasets: [],
      scheduledByCompany: {},
      actualByCompany: {},
    };
  }

  const scheduledByDate = createDatasetsByDate(crewSizeData.scheduled.dataset, dates);
  const actualByDate = createDatasetsByDate(crewSizeData.actual.dataset, dates);

  const scheduledByCompany = {};
  const actualByCompany = {};

  crewSizeData.scheduled.companies.forEach((company) => {
    scheduledByCompany[company.id] = dates.map((date) =>
      scheduledByDate[date] ? scheduledByDate[date][company.id] : null
    );

    actualByCompany[company.id] = dates.map((date) =>
      actualByDate[date] ? actualByDate[date][company.id] : null
    );
  });

  const scheduledDatasets = crewSizeData.scheduled.companies.map((company) => ({
    type: 'line',
    label: `${company.name} (Scheduled)`,
    data: scheduledByCompany[company.id],
    borderColor: companies[company.id].color,
    backgroundColor: companies[company.id].color,
    tension: 0.1,
    borderWidth: 2,
    order: 1,
    companyId: company.id,
  }));

  const actualDatasets = crewSizeData.actual.companies.map((company) => ({
    type: 'bar',
    label: `${company.name} (Actual)`,
    data: actualByCompany[company.id],
    backgroundColor: companies[company.id].color,
    order: 2,
    barThickness: 'flex',
    categoryPercentage: 0.95,
    barPercentage: 1.0,
    companyId: company.id,
  }));

  return {
    scheduledDatasets,
    actualDatasets,
    scheduledByCompany,
    actualByCompany,
  };
};

export const CrewSizeGraph = () => {
  const { projectId } = useParams();
  const [selectedCompanies, setSelectedCompanies] = useState([]);
  const [selectedTag, setSelectedTag] = useState('');
  const [dateRange, setDateRange] = useState({
    startDate: null,
    endDate: null,
  });
  const [viewOptions, setViewOptions] = useState({
    showScheduled: true,
    showActual: true,
    showScheduledTotal: false,
    showActualTotal: false,
  });

  const {
    data: crewSizeData,
    isLoading,
    isError,
  } = useGetCrewSizeQuery({ projectId, tagId: selectedTag || undefined });

  useEffect(() => {
    if (crewSizeData?.scheduled.companies) {
      setSelectedCompanies(crewSizeData.scheduled.companies.map((company) => company.id));
    }
  }, [crewSizeData]);

  const chartWidth = useMemo(() => {
    // Calculate chart width based on number of data points
    if (!crewSizeData?.scheduled.dataset) return 1000;
    const minWidthPerPoint = 25;
    const calculatedWidth = crewSizeData.scheduled.dataset.length * minWidthPerPoint;
    return Math.max(1000, calculatedWidth);
  }, [crewSizeData]);

  const dates = useMemo(() => {
    if (!crewSizeData?.scheduled.dataset && !crewSizeData?.actual.dataset) {
      return [new Date().toLocaleDateString()];
    }

    const uniqueDates = new Set();

    const addDatesFromDataset = (dataset) => {
      dataset.forEach((item) => {
        const date = new Date(item.date.split('T')[0] + 'T12:00:00');
        if (
          (!dateRange.startDate || date >= dateRange.startDate) &&
          (!dateRange.endDate || date <= dateRange.endDate)
        ) {
          uniqueDates.add(date.toLocaleDateString());
        }
      });
    };

    if (crewSizeData?.scheduled.dataset) {
      addDatesFromDataset(crewSizeData.scheduled.dataset);
    }

    if (crewSizeData?.actual.dataset) {
      addDatesFromDataset(crewSizeData.actual.dataset);
    }

    return Array.from(uniqueDates).sort((a, b) => new Date(a) - new Date(b));
  }, [crewSizeData, dateRange]);

  const chartOptions = useMemo(() => getChartOptions(dates), [dates]);

  const dateConstraints = useMemo(() => {
    if (!crewSizeData?.scheduled.dataset && !crewSizeData?.actual.dataset) {
      return { minDate: null, maxDate: null };
    }

    let minDate = new Date();
    let maxDate = new Date(0);

    const updateConstraints = (dataset) => {
      dataset.forEach((item) => {
        const date = new Date(item.date.split('T')[0] + 'T12:00:00');
        if (date < minDate) minDate = date;
        if (date > maxDate) maxDate = date;
      });
    };

    if (crewSizeData?.scheduled.dataset) {
      updateConstraints(crewSizeData.scheduled.dataset);
    }

    if (crewSizeData?.actual.dataset) {
      updateConstraints(crewSizeData.actual.dataset);
    }

    return { minDate, maxDate };
  }, [crewSizeData]);

  const companies = useMemo(() => {
    if (!crewSizeData?.scheduled.companies) return [];

    const colors = generateColors(crewSizeData.scheduled.companies.length);

    return crewSizeData.scheduled.companies.reduce((companiesById, company, index) => {
      companiesById[company.id] = {
        id: company.id,
        name: company.name,
        color: colors[index],
      };
      return companiesById;
    }, {});
  }, [crewSizeData]);

  // Filter base datasets based on view options. Recalculate totals.
  const baseDatasets = useMemo(
    () => createBaseDatasets(crewSizeData, dates, companies),
    [crewSizeData, dates, companies]
  );

  const datasets = useMemo(() => {
    const { scheduledDatasets, actualDatasets, scheduledByCompany, actualByCompany } = baseDatasets;

    if (scheduledDatasets.length === 0) {
      return [
        {
          label: 'No Data',
          data: [0],
          borderColor: '#e5e7eb',
          backgroundColor: '#e5e7eb',
        },
      ];
    }

    const visibleScheduledDatasets = viewOptions.showScheduled
      ? scheduledDatasets.filter((dataset) => new Set(selectedCompanies).has(dataset.companyId))
      : [];

    const visibleActualDatasets = viewOptions.showActual
      ? actualDatasets.filter((dataset) => new Set(selectedCompanies).has(dataset.companyId))
      : [];

    const scheduledTotalDataset = viewOptions.showScheduledTotal
      ? {
          type: 'line',
          label: 'Total Crew Size',
          data: dates.map((_, index) => {
            return selectedCompanies
              .filter((id) => id !== 'total')
              .reduce((sum, companyId) => {
                const value = scheduledByCompany[companyId]?.[index];
                return sum + (value || 0);
              }, 0);
          }),
          borderColor: '#000000',
          backgroundColor: '#000000',
          tension: 0.1,
          borderWidth: 2,
          borderDash: [5, 5],
          order: 0,
          companyId: 'total',
        }
      : {};

    const actualTotalDataset = viewOptions.showActualTotal
      ? {
          type: 'bar',
          label: 'Total Actual',
          data: dates.map((_, index) => {
            return selectedCompanies
              .filter((id) => id !== 'total')
              .reduce((sum, companyId) => {
                const value = actualByCompany[companyId]?.[index];
                return sum + (value || 0);
              }, 0);
          }),
          borderColor: '#00000040',
          backgroundColor: '#00000040',
          order: 0,
          barThickness: 'flex',
          categoryPercentage: 0.95,
          barPercentage: 1.0,
          companyId: 'actualTotal',
          isTotal: true,
        }
      : {};

    return [
      ...visibleScheduledDatasets,
      scheduledTotalDataset,
      ...visibleActualDatasets,
      actualTotalDataset,
    ];
  }, [baseDatasets, selectedCompanies, viewOptions, dates]);

  // This is what's actually given to chart.js
  const chartData = {
    labels: dates,
    datasets: datasets,
  };

  const handleCompanyToggle = (companyId) => {
    setSelectedCompanies((current) => {
      if (current.includes(companyId)) {
        return current.filter((id) => id !== companyId);
      } else {
        return [...current, companyId];
      }
    });
  };

  const handleToggleAll = () => {
    if (selectedCompanies.length > 0) {
      setSelectedCompanies([]);
    } else {
      setSelectedCompanies(crewSizeData.scheduled.companies.map((company) => company.id));
    }
  };

  const handleDateRangeChange = (start, end) => {
    setDateRange({ startDate: start, endDate: end });
  };

  if (isLoading) {
    return (
      <Box alignItems="center" display="flex" height={300} justifyContent="center">
        <CircularProgress />
      </Box>
    );
  }

  if (isError || !crewSizeData) {
    return (
      <Typography align="center" color="error" variant="body1">
        Unable to load crew size data
      </Typography>
    );
  }

  return (
    <Box
      sx={{
        padding: '16px',
        height: '95%',
        display: 'flex',
        flexDirection: 'column',
        minHeight: 0,
      }}
    >
      {/* Header */}
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          mb: 2,
          flexShrink: 0,
        }}
      >
        <Typography component="h2" variant="h6">
          Crew Size × Time
        </Typography>
        <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
          <DateRangeSelector
            endDate={dateRange.endDate}
            maxDate={dateConstraints.maxDate}
            minDate={dateConstraints.minDate}
            startDate={dateRange.startDate}
            onDateRangeChange={handleDateRangeChange}
          />
          <AnalyticsTagFilter projectId={projectId} onFilterChange={setSelectedTag} />
        </Box>
      </Box>

      {/* Chart container */}
      <Box
        sx={{
          flexGrow: 1,
          minHeight: 0,
          overflowX: 'auto',
          overflowY: 'hidden',
          mb: 2,
          '&::-webkit-scrollbar': { height: '8px' },
          '&::-webkit-scrollbar-track': {
            backgroundColor: '#f1f1f1',
            borderRadius: '4px',
          },
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: '#888888',
            borderRadius: '4px',
            '&:hover': {
              backgroundColor: '#666666',
            },
          },
        }}
      >
        <Box
          sx={{
            height: '100%',
            width: `${chartWidth}px`,
          }}
        >
          <Bar data={chartData} options={chartOptions} style={{ height: '100%', width: '100%' }} />
        </Box>
      </Box>

      {/* Legend Area */}
      <Box
        sx={{
          display: 'flex',
          gap: '8px',
          flexShrink: 0,
          height: 'auto',
          minHeight: '150px',
        }}
      >
        {/* Left Panel - View Options */}
        <Box sx={{ width: '140px' }}>
          <Typography
            variant="subtitle2"
            sx={{
              fontSize: '13px',
              mb: 1,
              color: 'text.secondary',
              fontWeight: 600,
            }}
          >
            CREW SIZE
          </Typography>
          <ButtonGroup orientation="vertical" size="small" sx={{ width: '100%' }}>
            <Button
              startIcon={<Timeline />}
              variant={viewOptions.showScheduled ? 'contained' : 'outlined'}
              sx={{
                fontSize: '12px',
                py: 0.5,
              }}
              onClick={() =>
                setViewOptions((prev) => ({ ...prev, showScheduled: !prev.showScheduled }))
              }
            >
              Scheduled
            </Button>
            <Button
              variant={viewOptions.showScheduledTotal ? 'contained' : 'outlined'}
              sx={{
                fontSize: '12px',
                py: 0.5,
              }}
              onClick={() =>
                setViewOptions((prev) => ({
                  ...prev,
                  showScheduledTotal: !prev.showScheduledTotal,
                }))
              }
            >
              Aggregate
            </Button>
          </ButtonGroup>

          <ButtonGroup orientation="vertical" size="small" sx={{ mt: 1, width: '100%' }}>
            <Button
              startIcon={<BarChart />}
              variant={viewOptions.showActual ? 'contained' : 'outlined'}
              sx={{
                fontSize: '12px',
                py: 0.5,
              }}
              onClick={() => setViewOptions((prev) => ({ ...prev, showActual: !prev.showActual }))}
            >
              Actual
            </Button>
            <Button
              variant={viewOptions.showActualTotal ? 'contained' : 'outlined'}
              sx={{
                fontSize: '12px',
                py: 0.5,
              }}
              onClick={() =>
                setViewOptions((prev) => ({ ...prev, showActualTotal: !prev.showActualTotal }))
              }
            >
              Aggregate
            </Button>
          </ButtonGroup>
        </Box>

        {/* Right Panel - Company Selection */}
        <Box
          sx={{
            flexGrow: 1,
            marginLeft: '24px',
            overflowY: 'auto',
            overflow: 'hidden',
          }}
        >
          <Typography
            variant="subtitle2"
            sx={{
              fontSize: '13px',
              mb: 1,
              color: 'text.secondary',
              fontWeight: 600,
            }}
          >
            COMPANIES
          </Typography>
          <Box
            sx={{
              display: 'flex',
              flexWrap: 'wrap',
              gap: 1,
              alignContent: 'flex-start',
            }}
          >
            <Chip
              label="Toggle All"
              size="small"
              sx={{ fontSize: '12px' }}
              variant="outlined"
              onClick={handleToggleAll}
            />
            {Object.values(companies).map((company) => (
              <Button
                key={company.id}
                size="small"
                variant="text"
                sx={{
                  py: 0.25,
                  height: '24px',
                  px: 1,
                  justifyContent: 'flex-start',
                  opacity: !selectedCompanies.includes(company.id) ? 0.2 : 1,
                  transition: 'opacity 0.2s ease',
                  minWidth: 'auto',
                  '&:hover': {
                    opacity: !selectedCompanies.includes(company.id) ? 0.2 : 0.5,
                    backgroundColor: 'transparent',
                  },
                }}
                onClick={() => handleCompanyToggle(company.id)}
              >
                <Box
                  sx={{
                    width: '10px',
                    height: '10px',
                    borderRadius: '50%',
                    backgroundColor: company.color,
                    mr: 0.5,
                    flexShrink: 0,
                  }}
                />
                <Typography
                  variant="body2"
                  sx={{
                    fontSize: '12px',
                    lineHeight: '14px',
                    whiteSpace: 'nowrap',
                  }}
                >
                  {company.name}
                </Typography>
              </Button>
            ))}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
