import { AnalyticsWindow } from './AnalyticsWindow';
import { CrewSizeGraph } from '../components/CrewSizeGraph';

export const analyticsRoutes = [
  {
    path: '',
    element: <AnalyticsWindow />,
  },
  {
    path: 'crew-size',
    element: <CrewSizeGraph />,
  },
];
