import { Grid, Link, ListItem, MenuItem, TextField } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { FormLabel, HighlighterLinkLabel } from 'assets/style-components/typography';
import { ColorSelect } from 'components/form/ColorSelect';
import { addYears, isValid } from 'date-fns';
import { PROJECT_STATUSES } from 'features/projects/api/constants';
import { COLORS } from 'helpers/constants';
import { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDebounceCallback } from 'usehooks-ts';

const defaultValues = {
  name: '',
  description: '',
  start_date: new Date(),
  sub_completion_date: addYears(new Date(), 1),
  color: COLORS[0].value,
  status: '',
};

export const BasicInfoForm = ({ onSubmit, initialData, canEdit }) => {
  const [isDescriptionVisible, setIsDescriptionVisible] = useState(false);
  const { control, watch, handleSubmit } = useForm({
    defaultValues: defaultValues,
    values: initialData,
  });

  const startDate = watch('start_date');

  const debouncedSaveFormState = useDebounceCallback(() => {
    handleSubmit(onSubmit)();
  });

  const handleToggleDescription = (event) => {
    event.preventDefault();
    canEdit && setIsDescriptionVisible(!isDescriptionVisible);
  };

  return (
    <form>
      <Grid container spacing={2}>
        <Grid item xs>
          <FormLabel>Project Name*</FormLabel>
          <Controller
            control={control}
            name="name"
            rules={{ required: 'Name field is required', onChange: debouncedSaveFormState }}
            render={({ field, fieldState: { error } }) => (
              <TextField
                {...field}
                autoFocus
                fullWidth
                disabled={!canEdit}
                error={!!error}
                helperText={error?.message}
                placeholder="Name"
                size="small"
                variant="outlined"
              />
            )}
          />
        </Grid>
        <Grid item xs={'auto'}>
          <FormLabel>Status</FormLabel>
          <Controller
            control={control}
            name="status"
            rules={{ onChange: debouncedSaveFormState }}
            render={({ field }) => (
              <TextField
                select
                size={'small'}
                {...field}
                disabled={!canEdit}
                sx={{ textTransform: 'capitalize', minWidth: '150px' }}
              >
                {PROJECT_STATUSES.map((option) => (
                  <MenuItem key={option} sx={{ textTransform: 'capitalize' }} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </TextField>
            )}
          />
        </Grid>
        <Grid item xs={'auto'}>
          <FormLabel>COLOR</FormLabel>
          <Controller
            control={control}
            name="color"
            render={({ field }) => <ColorSelect size={'small'} {...field} disabled={!canEdit} />}
            rules={{ onChange: debouncedSaveFormState }}
          />
        </Grid>
        <Grid item paddingTop={'0px !important'} xs={12}>
          <HighlighterLinkLabel>
            <Link
              component={'button'}
              disabled={!canEdit}
              type="button"
              onClick={handleToggleDescription}
            >
              {isDescriptionVisible ? 'Hide' : 'Add'} Description
            </Link>
          </HighlighterLinkLabel>
        </Grid>
        {isDescriptionVisible && (
          <Grid item xs={12}>
            <FormLabel>DESCRIPTION</FormLabel>
            <Controller
              control={control}
              name="description"
              rules={{ onChange: debouncedSaveFormState }}
              render={({ field }) => (
                <TextField
                  {...field}
                  fullWidth
                  multiline
                  disabled={!canEdit}
                  placeholder="Description"
                  rows={5}
                  size="small"
                  variant="outlined"
                />
              )}
            />
          </Grid>
        )}

        <Grid item xs={6}>
          <FormLabel>START DATE*</FormLabel>
          <Controller
            control={control}
            name="start_date"
            render={({ field: { ref, ...field }, fieldState: { error } }) => (
              <DatePicker
                {...field}
                disabled={!canEdit}
                inputRef={ref}
                slotProps={{
                  textField: {
                    size: 'small',
                    fullWidth: true,
                    error: !!error,
                    helperText: error?.message,
                  },
                }}
              />
            )}
            rules={{
              required: 'Project start date is required',
              validate: (value) => isValid(value) || 'Invalid Date',
              onChange: debouncedSaveFormState,
            }}
          />
        </Grid>

        <Grid item xs={6}>
          <FormLabel>SCHEDULED COMPLETION DATE*</FormLabel>
          <Controller
            control={control}
            name="sub_completion_date"
            render={({ field: { ref, ...field }, fieldState: { error } }) => (
              <DatePicker
                {...field}
                disabled={!canEdit}
                inputRef={ref}
                slotProps={{
                  textField: {
                    size: 'small',
                    fullWidth: true,
                    error: !!error,
                    helperText: error?.message,
                  },
                }}
              />
            )}
            rules={{
              required: 'Scheduled Completion date is required',
              validate: (value) =>
                !isValid(value)
                  ? 'Invalid Date'
                  : value >= startDate ||
                    'Scheduled end date should be greater than project start date',
              onChange: debouncedSaveFormState,
            }}
          />
        </Grid>
      </Grid>
    </form>
  );
};
