import { useCallback } from "react";

export const useGanttInlineEditorConfig = () => {
  const inlineEditorConfig = useCallback((gantt) => ({
    init: function (inlineEditors) {
      gantt.attachEvent("onTaskDblClick", function (id, e) {
        const cell = inlineEditors.locateCell(e.target);
        if (cell && inlineEditors.getEditorConfig(cell.columnName)) {
          inlineEditors.startEdit(cell.id, cell.columnName);
          return false;
        }
        return true;
      });

      gantt.attachEvent("onEmptyClick", function () {
        inlineEditors.hide();
        return true;
      });

      gantt.attachEvent("onTaskClick", function (id, e) {
        if (gantt._is_icon_open_click(e)) return true;
        var state = inlineEditors.getState();
        var cell = inlineEditors.locateCell(e.target);

        if (cell && inlineEditors.getEditorConfig(cell.columnName)) {
          if (inlineEditors.isVisible() && state.id == cell.id && state.columnName == cell.columnName) {
            inlineEditors.focus(e.target)
          }

          return false;
        }

        return true;
      });
    },

    onShow: function (inlineEditors, node) {

      node.onkeydown = function (event) {

        if (event.defaultPrevented) {
          return;
        }

        const keyboard = gantt.constants.KEY_CODES;
        let shouldPreventDefault = true;

        const handleSpaceKeyWithNavigation = () => {
          if (!gantt.ext?.keyboardNavigation) return;

          const keyNav = gantt.ext.keyboardNavigation;
          keyNav.attachEvent("onKeyDown", (command, e) => {
            return !inlineEditors.isVisible();
          });
          return false;
        };

        const keyActions = {
          [gantt.keys.edit_save]: () => inlineEditors.save(),
          [gantt.keys.edit_cancel]: () => inlineEditors.hide(),
          [keyboard.TAB]: () => {
            if (event.shiftKey) {
              inlineEditors.editPrevCell(true);
            } else {
              inlineEditors.editNextCell(true);
            }
          },
          [keyboard.SPACE]: () => {
            shouldPreventDefault = handleSpaceKeyWithNavigation();
          }
        };

        const action = keyActions[event.keyCode];
        if (action) {
          action();
        } else {
          shouldPreventDefault = false;
        }

        if (shouldPreventDefault) {
          event.preventDefault();
        }
      };
    },

    onHide: function () {
      // Any cleanup code if needed
    }
  }), []);

  return inlineEditorConfig;
};