import { api, providesList } from 'api';

export const USER_TAG = 'user';

export const usersAPI = api.enhanceEndpoints({ addTagTypes: [USER_TAG] }).injectEndpoints({
  endpoints: (build) => ({
    createUser: build.mutation({
      query: ({ companyId, user }) => ({
        url: `companies/${companyId}/users`,
        method: 'POST',
        body: user,
      }),
      invalidatesTags: (result, error, args) => (result ? [{ type: USER_TAG, id: 'LIST' }] : []),
    }),
    addUser: build.mutation({
      query: ({ companyId, userId, user }) => ({
        url: `companies/${companyId}/users/${userId}`,
        method: 'POST',
        body: user,
      }),
      invalidatesTags: (result, error, args) => (result ? [{ type: USER_TAG, id: 'LIST' }] : []),
    }),
    getUsersByCompanyId: build.query({
      query: ({ companyId }) => `companies/${companyId}/users`,
      providesTags: (data) => providesList(data, USER_TAG),
      transformResponse: (response) => response?.data,
    }),
    getUser: build.query({
      query: ({ companyId, userId }) => `/companies/${companyId}/users/${userId}`,
      providesTags: ({ id }) => [{ type: USER_TAG, id }],
      transformResponse: (response) => response?.data,
    }),
    getUserByEmail: build.query({
      query: ({ email }) => `/users/email/${email}`,
      providesTags: (response, error, { email }) => [{ type: USER_TAG, email }],
      transformResponse: (response) => response?.data,
    }),
    updateUser: build.mutation({
      query: ({ user }) => ({
        url: `/companies/${user.company_id}/users/${user.id}`,
        method: 'PATCH',
        body: user,
      }),
      async onQueryStarted({ user: patch }, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          api.util.updateQueryData(
            'getUsersByCompanyId',
            { companyId: String(patch.company_id) },
            (draft) => {
              const index = draft.findIndex((user) => user.id === patch.id);
              if (index !== -1) {
                draft[index] = { ...draft[index], ...patch };
              }
            }
          )
        );
        try {
          await queryFulfilled;
        } catch (error) {
          patchResult.undo();
        }
      },
      invalidatesTags: (result, error, args) => [{ type: USER_TAG, id: result.id }],

      transformResponse: (response) => response?.data,
    }),
    deleteUser: build.mutation({
      query: ({ companyId, userId }) => ({
        url: `/companies/${companyId}/users/${userId}/force`,
        method: 'DELETE',
      }),
      async onQueryStarted({ companyId, userId }, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          api.util.updateQueryData(
            'getUsersByCompanyId',
            { companyId: String(companyId) },
            (draft) => {
              const index = draft.findIndex((user) => user.id === userId);
              if (index !== -1) {
                draft.splice(index, 1);
              }
            }
          )
        );
        try {
          await queryFulfilled;
        } catch (error) {
          patchResult.undo();
        }
      },
      invalidatesTags: (result, error, { userId }) => [{ type: USER_TAG, id: userId }],
    }),
    archiveUser: build.mutation({
      query: ({ companyId, userId }) => ({
        url: `/companies/${companyId}/users/${userId}`,
        method: 'DELETE',
      }),
      async onQueryStarted({ companyId, userId }, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          api.util.updateQueryData(
            'getUsersByCompanyId',
            { companyId: String(companyId) },
            (draft) => {
              const index = draft.findIndex((user) => user.id === userId);
              if (index !== -1) {
                draft[index].deleted_at = new Date().toString();
              }
            }
          )
        );
        try {
          await queryFulfilled;
        } catch (error) {
          patchResult.undo();
        }
      },
      invalidatesTags: (result, error, { userId }) => [{ type: USER_TAG, id: userId }],
    }),
    restoreUser: build.mutation({
      query: ({ companyId, userId }) => ({
        url: `/companies/${companyId}/users/${userId}/restore`,
        method: 'PATCH',
      }),
      async onQueryStarted({ companyId, userId }, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          api.util.updateQueryData(
            'getUsersByCompanyId',
            { companyId: String(companyId) },
            (draft) => {
              const index = draft.findIndex((user) => user.id === userId);
              if (index !== -1) {
                draft[index].deleted_at = null;
              }
            }
          )
        );
        try {
          await queryFulfilled;
        } catch (error) {
          patchResult.undo();
        }
      },
      invalidatesTags: (result, error, { userId }) => [{ type: USER_TAG, id: userId }],
    }),
  }),
});
export const {
  useCreateUserMutation,
  useAddUserMutation,
  useLazyGetUserByEmailQuery,
  useGetUserQuery,
  useGetUsersByCompanyIdQuery,
  useUpdateUserMutation,
  useArchiveUserMutation,
  useRestoreUserMutation,
  useDeleteUserMutation,
} = usersAPI;
