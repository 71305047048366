import { formatTaskToGanttTask } from '@blackhyve/utilities/gantt';
import Close from '@mui/icons-material/Close';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { api } from 'api';
import { initGanttColumns } from 'components/projectOverview/gantt/ganttConfig/column/columnConfig';
import BulkEditMenu from 'components/projectOverview/gantt/ganttConfig/column/menu/BulkEditMenu';
import { drawFloatLayer } from 'components/projectOverview/gantt/ganttConfig/float';
import ganttStore from 'components/projectOverview/gantt/ganttConfig/ganttStore';
import { selectCurrentUserRole, selectCurrentUserWorkspaceId } from 'features/auth';
import { useSubscribeDependenciesQuery } from 'features/dependencies/api/dependency.api';
import PlanningBoardTaskDrawer from 'features/drawers/components/PlanningBoardTaskDrawer';
import Gantt from 'features/gantt/components/Gantt';
import { addForecastedDatesLayer } from 'features/gantt/layers/addForecastedDatesLayer';
import { addWeekdaysLayer } from 'features/gantt/layers/addWeekdaysLayer';
import { useGetNormalizedLbsQuery } from 'features/locations/store/location.api';
import { useGetProjectsQuery } from 'features/projects/store/project.api';
import { useSubscribeTasksQuery } from 'features/tasks/store/task.api';
import { closeSnackbar, useSnackbar } from 'notistack';
import { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import RecipeDrawer from '../../drawers/components/RecipeDrawer';
import { taskGanttEvents, taskGanttInlineEvents } from '../utils/gantt/taskGanttEvents';
import { drawCompanyColorLayer } from '../utils/gantt/taskGanttLayers';
import initMakers from '../utils/gantt/taskGanttMarkers';
import { taskGanttRenderer } from '../utils/gantt/taskGanttRenderer';
import { templates } from '../utils/gantt/taskGanttTemplates';
import { zoomConfig } from '../utils/gantt/zoomConfig';
import { useGetTradesQuery } from 'features/trades/api/trades.api';
import { useGetLabelsQuery } from 'features/labels/api/labels.api';
import {
  useFetchResourceAssignmentsQuery,
  useGetAllResourcesQuery,
} from 'features/resources/api/resources.api';
import { addDays, differenceInDays } from 'date-fns';
import { parseDate } from '@blackhyve/utilities/dates';

const emptyNormalizedArray = { ids: [], entities: {} };

export const TaskPortfolioGantt = ({ ganttId, initDataProcessor }) => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const gantt = ganttStore.getGantt(ganttId);
  const userRole = useSelector((state) => selectCurrentUserRole(state));
  const workspaceId = useSelector((state) => selectCurrentUserWorkspaceId(state));
  const { data: projects = [], isLoading: isProjectsLoading } = useGetProjectsQuery({
    workspaceId,
  });
  const projectIds = useMemo(() => projects?.map((project) => project.id), [projects]);
  const { data: { entities: tradeEntities = {} } = {}, isLoading: isLoadingTrades } =
    useGetTradesQuery();

  const { data: { labelEntities = {} } = {}, isLoading: isLoadingLabels } = useGetLabelsQuery();

  const { data: fetchedResources = [] } = useGetAllResourcesQuery({});
  const { data: fetchedResourceAssignments = [] } = useFetchResourceAssignmentsQuery();

  const {
    data: { ids: taskIds, entities, isTasksOutdated } = emptyNormalizedArray,
    isLoading: isLoadingTasks,
    refetch: refetchTasks,
  } = useSubscribeTasksQuery({ projectIds });

  const { data: dependencies = emptyNormalizedArray, isLoading: isLoadingDependencies } =
    useSubscribeDependenciesQuery({ projectIds });

  const ganttDependencies = useMemo(() => {
    return dependencies?.ids?.length ? Object.values(dependencies.entities) : [];
  }, [dependencies?.ids?.length, dependencies.entities]);

  const projectTasks = useMemo(() => {
    return projects.map((project) => {
      const projectStartDate = parseDate(project?.start_date);
      const projectScheduledEndDate = addDays(parseDate(project?.sub_completion_date), 1);
      const projectForecastedEndDate = project?.est_completion_date
        ? addDays(parseDate(project?.est_completion_date), 1)
        : projectScheduledEndDate;
      return {
        id: project.id * -1,
        text: project?.name,
        type: 'project_bar',
        status: 'todo',
        start_date: parseDate(project?.start_date),
        end_date: projectForecastedEndDate,
        scheduled_end_date: projectScheduledEndDate,
        forecasted_start_date: projectStartDate,
        forecasted_end_date: projectForecastedEndDate,
        duration_string: differenceInDays(projectStartDate, projectForecastedEndDate),
        cal_days: differenceInDays(projectForecastedEndDate, projectStartDate),
        open: true,
        readonly: true,
        project_id: project?.id,
        holidays: project.holidays,
        $skipDataProcessor: true,
      };
    });
  }, [projects]);

  const ganttTasks = useMemo(
    () =>
      taskIds
        .map((id) => formatTaskToGanttTask(entities[id]))
        .map((task) => {
          const project = projects?.find((project) => project.id === task.project_id);
          task.calendar_id = `${task.project_id}-calendar`;
          task.readonly = !(
            userRole === 'super_admin' ||
            userRole === 'admin' ||
            (project?.user_access_level
              ? project?.user_access_level === 'full_access'
              : project?.general_access_level === 'full_access')
          );
          if (!task.parent) {
            task.parent = `${project.id * -1}`;
          }
          return task;
        }),
    [projects, taskIds, entities, userRole]
  );
  const { data: lbsData, isLoading: isLoadingLbs } = useGetNormalizedLbsQuery();

  const isLoading =
    isLoadingDependencies ||
    isLoadingTasks ||
    isLoadingLbs ||
    isProjectsLoading ||
    isLoadingTrades ||
    isLoadingLabels;

  const getGanttColumn = () => {
    return initGanttColumns(ganttId);
  };

  const handleReloadTrigger = useCallback(async () => {
    dispatch(
      api.util.updateQueryData('subscribeTasks', { projectIds }, (draft) => {
        draft.isTasksOutdated = undefined;
      })
    );
    closeSnackbar('Gantt Out Of Date');
    const { x, y } = { ...gantt.getScrollState() };
    const {
      data: { ids: taskIds, entities: taskEntities },
    } = await refetchTasks();
    gantt?.clearAll();
    gantt?.parse({
      tasks: taskIds.map((taskId) => formatTaskToGanttTask(taskEntities[taskId])),
      links: dependencies,
    });
    gantt.scrollTo(x, y);
  }, [dispatch, projectIds, refetchTasks, gantt, dependencies]);

  useEffect(() => {
    return () => {
      closeSnackbar('Gantt Out Of Date');
      dispatch(
        api.util.updateQueryData('subscribeTasks', { projectIds }, (draft) => {
          draft.isTasksOutdated = undefined;
        })
      );
    };
  }, [dispatch, projectIds]);

  useEffect(() => {
    if (isTasksOutdated && !isLoading) {
      enqueueSnackbar('Gantt is out of date please reload', {
        key: 'Gantt Out Of Date',
        action: (id) => (
          <>
            <Button color={'secondary'} variant={'text'} onClick={handleReloadTrigger}>
              Reload
            </Button>
            <IconButton color={'error'} onClick={() => closeSnackbar(id)}>
              <Close />
            </IconButton>
          </>
        ),
        preventDuplicate: true,
        persist: true,
      });
    }
  }, [isTasksOutdated, enqueueSnackbar, handleReloadTrigger, isLoading]);
  return !isLoading ? (
    <>
      <Gantt
        columns={getGanttColumn()}
        ganttId={ganttId}
        initDataProcessor={initDataProcessor}
        initMakers={initMakers}
        inlineEvents={taskGanttInlineEvents}
        layers={[addWeekdaysLayer, drawFloatLayer, drawCompanyColorLayer, addForecastedDatesLayer]}
        plugins={{ critical_path: true, grouping: true, tooltip: true }}
        resourceAssignments={fetchedResourceAssignments}
        resources={fetchedResources}
        templates={templates}
        typeRenders={{ task: taskGanttRenderer, project: taskGanttRenderer }}
        zoomConfig={zoomConfig}
        config={{
          highlight_critical_path: false,
          fit_tasks: false,
          auto_types: false,
          sort: true,
          placeholder_task: false,
          order_branch: false,
          isTaskPortfolio: true,
        }}
        constants={{
          lbsData,
          projects,
          labels: labelEntities,
        }}
        data={{
          tasks: [...projectTasks, ...ganttTasks],
          links: ganttDependencies,
        }}
        events={[
          ...taskGanttEvents,
          {
            onParse: function () {
              // this._sort = { name: 'start_date', direction: 'desc' };
              // this.sort('start_date');
              this._sort = { name: '$display_order', direction: 'asc' };
              this.sort('$display_order');
            },
          },
        ]}
      />
      <RecipeDrawer ganttId={ganttId} />
      <BulkEditMenu ganttId={ganttId} />
      <PlanningBoardTaskDrawer ganttId={ganttId} />
    </>
  ) : (
    <Grid
      container
      alignItems={'center'}
      direction={'column'}
      justifyContent={'center'}
      style={{ height: '100%' }}
    >
      <CircularProgress />
      <Typography>Please Wait...</Typography>
    </Grid>
  );
};
