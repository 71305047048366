import { Grid, MenuItem } from '@mui/material';
import { FormTextField } from 'assets/style-components/textfield';
import { FormLabel } from 'assets/style-components/typography';
import { Controller, useForm } from 'react-hook-form';
import { useDebounceCallback } from 'usehooks-ts';

const notificationFrequencyOptions = ['off', 'immediately', 'hourly', 'daily'];

const defaultValues = {
  notification_frequency: 'daily',
};

export const NotificationsForm = ({ onSubmit, initialData, canEdit }) => {
  const { control, handleSubmit } = useForm({
    defaultValues,
    values: initialData,
  });

  const debouncedSaveFormState = useDebounceCallback(() => {
    handleSubmit(onSubmit)();
  });

  return (
    <form>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <FormLabel>Frequency</FormLabel>
          <Controller
            control={control}
            name="notification_frequency"
            rules={{ onChange: debouncedSaveFormState }}
            render={({ field }) => (
              <FormTextField
                {...field}
                fullWidth
                select
                disabled={!canEdit}
                size="small"
                variant="outlined"
              >
                {notificationFrequencyOptions.map((option) => (
                  <MenuItem key={option} sx={{ textTransform: 'capitalize' }} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </FormTextField>
            )}
          />
        </Grid>
      </Grid>
    </form>
  );
};
