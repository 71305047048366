import { api, providesList } from 'api';
import { BUDGET_CODE_KEY } from 'features/budgets/store/budget-codes.api';
import { BUDGET_LINE_KEY } from 'features/budgets/store/budget-lines.api';
import { CHANGE_ORDER_LINE_KEY } from 'features/budgets/store/change-order-lines.api';
import { CONTRACT_LINE_KEY } from 'features/budgets/store/contract-lines.api';
import { INVOICE_LINE_KEY } from 'features/budgets/store/invoice-lines.api';
import { DEPENDENCY_TAG } from 'features/dependencies/api/dependency.api';
import { USER_TAG } from 'features/contacts/api/users.api';
import { FIELD_VALUES_TAG } from 'features/labels/api/fieldValues.api';
import { LABELS_CACHE_KEY } from 'features/labels/api/labels.api';
import { PROCUREMENT_TAG } from 'features/procurement/store/procurement.api';
import { PROJECT_TAG } from 'features/projects/store/project.api';
import { RESOURCE_ASSIGNMENT_TAG, RESOURCE_TAG } from 'features/resources/api/resources.api';
import { TAGS_CACHE_KEY } from 'features/tags/store/tag.api';

export const workspaceAPI = api
  .enhanceEndpoints({ addTagTypes: ['Feature', 'Workspace'] })
  .injectEndpoints({
    endpoints: (build) => ({
      getWorkspaces: build.query({
        query: ({ userId }) => `/users/${userId}/workspaces`,
        providesTags: (result, error, args) => (result ? providesList(result, 'Workspace') : []),
        transformResponse: (response) => response.data,
      }),
      setWorkspace: build.mutation({
        queryFn: ({ workspaceId, workspaceName, workspaceLogo }, { getState, dispatch }) => ({
          data: { workspaceId, workspaceName, workspaceLogo },
        }),
        invalidatesTags: [
          USER_TAG,
          'Office',
          'ActivityFeed',
          'Company',
          'Notification',
          'Feature',
          'Task',
          PROJECT_TAG,
          DEPENDENCY_TAG,
          'Location',
          'Zone',
          'Area',
          'StuckPoint',
          'Jobwalk',
          'Recipe',
          'Baseline',
          'Budget',
          BUDGET_LINE_KEY,
          BUDGET_CODE_KEY,
          CHANGE_ORDER_LINE_KEY,
          'Contract',
          CONTRACT_LINE_KEY,
          'Forecast',
          'Invoice',
          INVOICE_LINE_KEY,
          LABELS_CACHE_KEY,
          TAGS_CACHE_KEY,
          PROCUREMENT_TAG,
          RESOURCE_TAG,
          RESOURCE_ASSIGNMENT_TAG,
          FIELD_VALUES_TAG,
        ],
      }),
      getWorkspaceFeatures: build.query({
        query: () => `/available_features`,
        providesTags: (result, error, args) => (result ? [{ type: 'Feature' }] : []),
        transformResponse: (response) => response.data,
      }),
    }),
  });
export const { useGetWorkspacesQuery, useSetWorkspaceMutation, useGetWorkspaceFeaturesQuery } =
  workspaceAPI;
