import { useEffect } from 'react';

export const useGanttDisplayOption = (gantt, displayOptions) => {
  useEffect(() => {
    if (gantt && displayOptions) {
      gantt.attachEvent(
        'onBeforeTaskDisplay',
        function (taskId, task) {
          task.$displayOptions = displayOptions;
          return true;
        },
        { id: 'displayOptions' }
      );

      if (gantt.$root) gantt?.render();
    }
  }, [displayOptions, gantt]);
};
