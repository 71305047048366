import { AddBox, DragIndicator, MoreVert, VerticalAlignBottom } from '@mui/icons-material';
import { Box, Button, Checkbox, IconButton, Stack } from '@mui/material';
import ganttStore from 'components/projectOverview/gantt/ganttConfig/ganttStore';
import { insertChildTask, insertTaskBelow } from 'features/tasksGantt/utils/taskActions/insertTask';
import { useState } from 'react';
import TaskContextMenu from '../TaskContextMenu';

export const ActionColumnCell = ({ task, ganttId }) => {
  const gantt = ganttStore.getGantt(ganttId);
  const isTaskSelected = gantt.isSelectedTask(task.id);
  const isReadonly = gantt.config.readonly || (task.readonly && task.type !== 'project_bar');
  const isProjectBar = task.id < 0;

  const [contextMenuAnchor, setContextMenuAnchor] = useState(undefined);

  const handleAddChild = () => {
    insertChildTask(task.id, gantt);
  };

  const handleToggleAutoScheduleDate = () => {
    if (task.autoschedule_date === 'forecasted') {
      task.autoschedule_date = 'schedule';
    } else {
      task.autoschedule_date = 'forecasted';
    }
    gantt.updateTask(task.id);
    gantt.autoSchedule(task.id);
  };

  const isDragDisabled = task.status === 'complete' || task?.dates_locked_by || !gantt.config.order_branch
  const dragIndicatorStyles = {
    opacity: isDragDisabled ? 0.3 : 1,
    cursor: isDragDisabled ? 'not-allowed' : 'grab',
  };

  return (
    <Stack alignItems={'center'} data-task-id={task.id} direction={'row'} width={'100%'}>
      <DragIndicator
        className={`drag-drop ${isDragDisabled && 'disabled'}`}
        sx={dragIndicatorStyles}
      />
      <IconButton
        size={'small'}
        sx={{ p: 0.2 }}
        onClick={(e) => {
          e.stopPropagation();
          setContextMenuAnchor(e.currentTarget);
        }}
      >
        <MoreVert fontSize={'inherit'} />
      </IconButton>
      {contextMenuAnchor && (
        <TaskContextMenu
          anchor={contextMenuAnchor}
          ganttId={ganttId}
          handleClose={() => setContextMenuAnchor(undefined)}
          id={task.id}
        />
      )}
      {task.id > 0 && (
        <Checkbox
          checked={isTaskSelected}
          id={`checkbox-${task.id}`}
          size={'small'}
          sx={{ p: 0.2 }}
        />
      )}
      <Box mr={'auto'} />

      {!isProjectBar && (
        <Button
          className={'toggle_autoschedule_date'}
          color={task.autoschedule_date === 'forecasted' ? 'primary' : 'secondary'}
          disabled={isReadonly}
          size={'small'}
          sx={{ p: 0.2, minWidth: 0 }}
          onClick={handleToggleAutoScheduleDate}
        >
          {task.autoschedule_date === 'forecasted' ? 'F' : 'S'}
        </Button>
      )}
      {!isProjectBar && (
        <IconButton
          className={'insert_child'}
          color={'primary'}
          disabled={isReadonly}
          size={'small'}
          sx={{ p: 0 }}
          onClick={handleAddChild}
        >
          <VerticalAlignBottom />
        </IconButton>
      )}
      <IconButton
        className={'insert_below'}
        color={'primary'}
        disabled={isReadonly}
        size={'small'}
        sx={{ p: 0 }}
        onClick={() => insertTaskBelow(task.id, gantt)}
      >
        <AddBox />
      </IconButton>
    </Stack>
  );
};
