import isEqual from 'lodash/isEqual';
import Switch from '@mui/material/Switch';
import { useEffect, useMemo, useState } from 'react';
import ganttStore from '../../ganttConfig/ganttStore';
import { useDispatch, useSelector } from 'react-redux';
import ArrowDropUp from '@mui/icons-material/ArrowDropUp';
import { MenuButton } from 'assets/style-components/button';
import ArrowDropDown from '@mui/icons-material/ArrowDropDown';
import { registerMenuState, updateMenuState } from 'slices/customViewSlice';
import { List, ListItem, ListItemText, ListSubheader, Popover } from '@mui/material';


const filterTags = (tags, type, smartKey) => {
  return tags?.length ? tags?.filter((tag) => tag.type === type && tag.smart_key === smartKey) : [];
}


const initialState = {
  // scope: {
  //   displayText: 'Add Scope',
  //   enabled: true,
  //   render: (task) => {
  //     return task.is_scope ?
  //       reactElementToJSXString(ScopeIcon({ style: "margin-right: 5px" }))
  //       : ''
  //   }
  // },
  company: {
    displayText: 'Company',
    enabled: true,
    render: (task) => {
      return task?.companies?.length > 0
        ? `<div>
        ${task?.companies
          .map((company, index) => {
            return company?.logo?.thumb_url || company?.profile_image?.thumb_url
              ? `<img src="${company?.logo?.thumb_url || company?.profile_image?.thumb_url
              }" style="width: 16px; height: 16px; border-radius: 20px; position: relative; vertical-align: middle; z-index:${task?.companies?.length - index
              }; margin-left:${index > 0 ? '-8px' : '0px'}"/>`
              : `<div class="company-avatar" style="z-index:${task?.companies?.length - index
              }; margin-left:${index > 0 ? '-8px' : '0px'}">
              ${company?.name?.charAt(0).toUpperCase()}${company?.name.charAt(1).toUpperCase()}
              </div>`;
          })
          .join('')}
      </div>`
        : '';
    },
  },
  taskName: {
    displayText: 'Task Name',
    groupTitle: true,
  },
  status: {
    displayText: 'Status',
    enabled: true,
    render: (task) => {
      if (task.status && task.status !== 'todo') {
        const capitalizedStatus =
          task.status.charAt(0).toUpperCase() + task.status.slice(1).toLowerCase();
        return `<span class='task-status'> - ${capitalizedStatus} - </span>`;
      } else {
        return '';
      }
    },
  },
  name: {
    displayText: 'Name',
    enabled: true,
    render: (task) => `<span class='task-name'>${task.text}</span>`,
  },
  location: {
    displayText: 'Location',
    enabled: true,
    render: (task) => {
      const locationTagName = filterTags(task?.tags, 'generated', 'location')?.map((tag) => tag.name).join('-');
      return locationTagName ? `<span class='task-location task-generated-tag'> - ${locationTagName}</span>` : '';
    },
  },
  phase: {
    displayText: 'Phase',
    enabled: true,
    render: (task) => {
      const phaseTagNames = filterTags(task?.tags, 'generated', 'phase')?.map((tag) => tag.name).join('-');
      return phaseTagNames ? `<span class='task-phase task-generated-tag'> - ${phaseTagNames} </span>` : ''
    },
  },
  zone: {
    displayText: 'Zone',
    enabled: true,
    render: (task) => {
      const zoneTagNames = filterTags(task?.tags, 'generated', 'zone')?.map((tag) => tag.name).join('-');
      return zoneTagNames ? `<span class='task-zone task-generated-tag'> - ${zoneTagNames} </span>` : ''
    },
  },
  area: {
    displayText: 'Area',
    enabled: true,
    render: (task) => {
      const areaTagNames = filterTags(task?.tags, 'generated', 'area')?.map((tag) => tag.name).join('-');
      return areaTagNames ? `<span class='task-area task-generated-tag'> - ${areaTagNames} </span>` : ''
    },
  },
  project: {
    displayText: 'Project',
    enabled: true,
    render: (task, gantt) => {
      const projects = gantt?.constants?.projects;
      const project = projects?.find((project) => project.id === task.project_id);
      return project && project.name ? `<span class='project-name'> - ${project.name}` : ''
    }
  }
};

const options = {};
Object.keys(initialState).forEach((key) => {
  if (initialState[key].enabled !== undefined) {
    options[key] = initialState[key].enabled;
  }
});

const DisplayMenu = ({ ganttId }) => {
  const dispatch = useDispatch();
  const gantt = ganttStore.getGantt(ganttId);

  const selectedOptions = useSelector((state) => state.customViews?.menus?.display?.currentValue);

  const [anchorEl, setAnchorEl] = useState(null);

  useEffect(() => {
    if (gantt && selectedOptions) {
      const display = Object.assign({ ...selectedOptions }, initialState);
      Object.entries(selectedOptions).forEach(([key, value]) => {
        if (!value) {
          delete display[key];
        }
      });
      gantt.config.display = display;
      gantt.render();
    }
  }, [selectedOptions, gantt, ganttId]);

  const handleToggleDisplayOption = (event, optionKey) => {
    dispatch(
      updateMenuState({
        name: 'display',
        value: { ...selectedOptions, [optionKey]: !selectedOptions[optionKey] },
      })
    );
  };
  const displayOptions = useMemo(() => {
    if (!gantt?.config?.isTaskPortfolio) {
      const { project, ...rest } = initialState;
      return rest;
    }
    return initialState;
  }, [gantt?.config?.isTaskPortfolio]);


  useEffect(() => {
    dispatch(
      registerMenuState({
        name: 'display',
        value: options,
      })
    );
  }, [dispatch]);

  return (
    <>
      <MenuButton
        endIcon={Boolean(anchorEl) ? <ArrowDropUp /> : <ArrowDropDown />}
        isHighlighted={!isEqual(options, selectedOptions)}
        onClick={(event) => setAnchorEl(event.currentTarget)}
      >
        Display
      </MenuButton>
      {anchorEl !== null ? (
        <Popover
          anchorEl={anchorEl}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
          open={Boolean(anchorEl)}
          transformOrigin={{ vertical: 'top', horizontal: 'left' }}
          onClose={() => setAnchorEl(null)}
        >
          <List dense>
            {Object.keys(displayOptions).map((optionKey) => {
              const option = displayOptions[optionKey];
              const isEnabled = selectedOptions[optionKey];
              return (
                <>
                  {option.groupTitle ? (
                    <ListSubheader sx={{ fontWeight: 'bold' }}>{option.displayText}</ListSubheader>
                  ) : (
                    <ListItem
                      key={option.displayText}
                      secondaryAction={
                        !option.groupTitle ? (
                          <Switch
                            checked={isEnabled}
                            color="primary"
                            edge="end"
                            size="small"
                            onClick={(event) => handleToggleDisplayOption(event, optionKey)}
                          />
                        ) : (
                          ''
                        )
                      }
                    >
                      <ListItemText primary={option.displayText} />
                    </ListItem>
                  )}
                </>
              );
            })}
          </List>
        </Popover>
      ) : null}
    </>
  );
};

export default DisplayMenu;
