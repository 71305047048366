import { addWeeks } from 'date-fns';
import { useCallback } from 'react';
import { LookaheadActionCell } from '../components/LookaheadActionCell';
import { layout as defaultLayout } from '../config/layout';
import { scales as defaultScales } from '../config/scales';

export const useLookaheadConfig = ({
  startDate,
  weeks = 7,
  layout = defaultLayout,
  scales = defaultScales,
}) => {
  const getConfig = useCallback(
    (gantt) => ({
      columns: [
        {
          name: 'text',
          label: 'Name',
          align: 'left',
          tree: true,
          width: 390,
          editor: { type: 'text', map_to: 'text' },
          template: (task) => {
            const displayOptions = task.$displayOptions;
            const display = displayOptions
              .filter((option) => option.isEnabled)
              .map((option) => option.renderTaskListMarkup(task, gantt)).filter((value) => value);

            return `<span class="${task.id === -1 || gantt.config.readonly ? 'cell_disabled' : ''} flex">${[task.text, ...display].join('&nbsp')}</span > `;
          },
        },
        {
          name: 'crew_size',
          label: 'Crew',
          width: 60,
          editor: { type: 'number', map_to: 'crew_size' },
          template: function (task) {
            return task.crew_size;
          },
        },
        {
          name: 'action',
          width: 102,
          onrender: function (task, node) {
            return (
              <LookaheadActionCell gantt={gantt} key={`${task.id}-action-column`} task={task} />
            );
          },
        },
      ],
      row_height: 50,
      bar_height: 35,
      parent_row_height: 35,
      parent_bar_height: 20,
      show_tasks_outside_timescale: true,
      auto_types: false,
      static_background: false,
      start_date: startDate,
      end_date: addWeeks(startDate, weeks),
      layout: layout,
      scales: scales,
      min_column_width: 20,
      scale_height: 50,
      type_renderers: {
        task: function (task, defaultRender) {
          const gantt = this;
          const taskBar = document.createElement('div');
          taskBar.className = 'gantt_bar_task gantt_task_line hidden';
          taskBar.setAttribute('task_id', task.id);
          taskBar.dataset.taskId = task.id;

          const size = gantt.getTaskPosition(task);
          taskBar.style.left = size.left + 3 + 'px';
          taskBar.style.top = size.top + (task.row_height - task.bar_height) / 2 + 'px';
          taskBar.style.width = size.width - 6 + 'px';
          taskBar.style.height = size.height + 'px';
          taskBar.style.lineHeight = task.bar_height + 'px';

          taskBar.innerHTML = `
              <div class="gantt_side_content gantt_left gantt_link_crossing">${gantt.templates.leftside_text(task.start_date, task.end_date, task)}</div>             
              <div
                class="gantt_link_control task_left task_start_date"
                data-bind-property="start_date"
                style="height: ${task.bar_height}px; line-height: ${task.bar_height}px;"
              >
                <div class="gantt_link_point"></div>
              </div>
              <div
                class="gantt_link_control task_right task_end_date"
                data-bind-property="end_date"
                style="height: ${task.bar_height}px; line-height: ${task.bar_height}px;"
              >
                <div class="gantt_link_point"></div>
              </div>
              <div class="gantt_side_content gantt_right ">${gantt.templates.rightside_text(task.start_date, task.end_date, task)}</div>
              `;

          return taskBar;
        },
      },
    }),
    [layout, scales, startDate, weeks]
  );
  return getConfig;
};
