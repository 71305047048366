import { GanttStatic } from '@blackhyve/dhtmlx-gantt';
import { ResourcesStore } from '../stores/resources.store';
import { updateGanttDemand } from './updateGanttDemand';

function resourceCellClass(value: number) {
  const intensity = Math.min(value, 1.0);

  if (intensity === 1) {
    return 'resource_cell--over-capacity';
  } else if (intensity >= 0.8 && intensity < 1) {
    return 'resource_cell--at-capacity';
  }

  return 'resource_cell--under-capacity';
}

function resourceCellValue(start_date: Date, end_date: Date, resource, tasks) {
  const resourcesStore = new ResourcesStore();
  const { allocation, capacity } = resourcesStore.getResourceDelta(
    start_date,
    end_date,
    resource,
    tasks
  );
  const label = allocation > 0 ? Number((capacity - allocation).toFixed(0)).toLocaleString() : '';
  const ratio = allocation / capacity;
  const cellClass = allocation > 0 ? resourceCellClass(ratio) : 'resource_cell--unallocated ';

  return `<div class="resource_cell ${cellClass}">${label}</div>`;
}

export namespace ResourcesPanel {
  export function gridRowClass(start, end, resource) {
    const css: string[] = [];
    const resourcesStore = new ResourcesStore();
    if (resourcesStore.hasChild(resource.id)) {
      css.push('folder_row');
      css.push('group_row');
    }
    return css.join(' ');
  }

  export function taskRowClass(start, end, resource) {
    const css: string[] = [];
    const resourcesStore = new ResourcesStore();
    if (resourcesStore.hasChild(resource.id)) {
      css.push('group_row');
    }

    return css.join(' ');
  }
}

export function setupResources(gantt: GanttStatic) {
  gantt.config.resource_render_empty_cells = true;

  const store = new ResourcesStore(gantt);

  gantt.templates = {
    ...gantt.templates,
    resource_cell_value: resourceCellValue,
    resource_cell_class: () => ' ',
  };

  gantt.attachEvent('onParse', () => {
    updateGanttDemand(gantt);
  });

  gantt.attachEvent('onAfterTaskUpdate', () => {
    updateGanttDemand(gantt);
  });

  return { store };
}
