import { createEntityAdapter } from '@reduxjs/toolkit';
import { DEPENDENCY_TAG } from 'features/dependencies/api/dependency.api';
import { TAGS_CACHE_KEY } from 'features/tags/store/tag.api';
import { objectToFormData } from 'helpers/objectToFormData';
import { getSocket } from 'helpers/websockets';
import { normalize, schema } from 'normalizr';

const { api, providesList } = require('api');

export const TASK_CACHE_TYPE = 'Task';

const normalizeTask = (tasks) => {
  const dependencySchema = new schema.Entity('dependencies');
  const taskSchema = new schema.Entity('task', {
    dependencies: [dependencySchema],
  });
  const schemaArray = new schema.Array(taskSchema);
  return normalize(tasks, schemaArray);
};

const taskAdapter = createEntityAdapter({
  sortComparer: (prev, next) => {
    return prev.display_order - next.display_order;
  },
});
const taskAPI = api
  .enhanceEndpoints({ addTagTypes: [TASK_CACHE_TYPE, 'TaskActivity'] })
  .injectEndpoints({
    endpoints: (build) => ({
      getTask: build.query({
        query: ({ taskId, ...queryParam }) => ({
          url: `/tasks/${taskId}`,
          params: queryParam,
        }),
        providesTags: (result, error, args) =>
          result ? [{ type: TASK_CACHE_TYPE, id: result?.id }] : [],
        transformResponse: (response) => response.data,
      }),
      getTaskParents: build.query({
        query: ({ taskId }) => ({
          url: `tasks/${taskId}/parents`,
        }),
        providesTags: (result, error, args) =>
          result
            ? [
              { type: TASK_CACHE_TYPE, id: args?.taskId },
              ...providesList(result, TASK_CACHE_TYPE),
            ]
            : [],
        transformResponse: (response) => response.data,
      }),
      getTaskColors: build.query({
        query: ({ projectId }) => `projects/${projectId}/task_colors`,
        transformResponse: (response) => response.data,
        providesTags: (response, error, args) =>
          response
            ? Object.values(response).flatMap((value) =>
              providesList(Object.keys(value), TASK_CACHE_TYPE)
            )
            : [],
      }),
      getTaskTree: build.query({
        query: ({
          projectId,
          locationIds,
          companyIds,
          userIds,
          tradeIds,
          color,
          startDate,
          endDate,
        }) => ({
          url: `tasks_tree`,
          params: {
            project: projectId,
            'location[]': locationIds,
            'company[]': companyIds,
            'responsible[]': userIds,
            'trade[]': tradeIds,
            date_range: startDate && endDate ? `${startDate}..${endDate}` : undefined,
            // color,
          },
        }),
        transformResponse: (response) => response.data,
      }),
      getTasks: build.query({
        query: ({ projectId, ...queryParam }) => {
          const queryString = Object.entries(queryParam)
            .filter(([key, value]) => value !== undefined)
            .map(([key, value]) => `${key}=${value}`)
            .join('&');
          return `/tasks?${projectId ? `project=${projectId}&` : ''}${queryString}`;
        },
        transformResponse: (response) => {
          return taskAdapter.setAll(taskAdapter.getInitialState(), response.data);
        },
        providesTags: (response) => {
          return response?.ids ? providesList(response?.ids, TASK_CACHE_TYPE) : [];
        },
      }),

      subscribeTasks: build.query({
        query: ({ projectId, ...params } = {}) => ({
          url: `/tasks`,
          params: { project: projectId, include: 'latestJobWalk,company,tags' },
        }),
        transformResponse: (response) => {
          return {
            ...taskAdapter.setAll(taskAdapter.getInitialState(), response.data),
            isTasksOutdated: response.isTasksOutdated,
          };
        },
        providesTags: (response) => (response ? providesList(response.ids, TASK_CACHE_TYPE) : []),
        async onCacheEntryAdded(
          arg,
          { updateCachedData, cacheDataLoaded, cacheEntryRemoved, getState }
        ) {
          await cacheDataLoaded;
          const { workspaceId } = getState().auth;
          const socket = getSocket();

          const channelNames = arg?.projectId
            ? [`workspaces.${workspaceId}.projects.${arg?.projectId}.tasks`]
            : arg?.projectIds?.map(
              (projectId) => `workspaces.${workspaceId}.projects.${projectId}.tasks`
            );

          try {
            const handleCreateTask = (data) => {
              updateCachedData((draft) => {
                taskAdapter.upsertMany(draft, data);
                draft.isTasksOutdated = true;
              });
            };
            const handleUpdateTasks = (data) => {
              updateCachedData((draft) => {
                taskAdapter.upsertMany(draft, data);
                draft.isTasksOutdated = true;
              });
            };
            const handleDeleteTask = (data) => {
              updateCachedData((draft) => {
                taskAdapter.removeMany(draft, data);
                draft.isTasksOutdated = true;
              });
            };
            const eventListeners = {
              TaskUpdated: handleUpdateTasks,
              TaskCreated: handleCreateTask,
              TaskDeleted: handleDeleteTask,
            };

            channelNames?.forEach((channelName) => {
              const connectedChannel = socket.join(channelName);
              for (const [event, listener] of Object.entries(eventListeners)) {
                const eventListener = (data) => {
                  listener(data);
                };
                connectedChannel.listen(event, eventListener);
              }
            });
          } catch (error) {
            console.log('Error while connecting to sockets =>', error);
          }
          await cacheEntryRemoved;
          channelNames?.forEach((channelName) => {
            socket?.leave(channelName);
          });
        },
      }),

      createTask: build.mutation({
        query: ({ task }) => {
          let body = task;
          if (task?.map instanceof File || task?.original_map instanceof File) {
            console.log('in here');
            body = objectToFormData(task);
          }
          return {
            url: `/tasks`,
            method: 'POST',
            body,
          };
        },
        invalidatesTags: (result, error, args) =>
          result ? [{ type: TASK_CACHE_TYPE, id: 'LIST' }] : [],
        transformResponse: (response) => response.data,
      }),

      createTasks: build.mutation({
        query: ({ tasks }) => ({
          url: '/tasks/bulk',
          method: 'POST',
          body: tasks,
        }),
        invalidatesTags: (result, error, args) =>
          result ? [{ type: TASK_CACHE_TYPE, id: 'LIST' }] : [],
        transformResponse: (response) => response.data,
      }),

      updateTasks: build.mutation({
        query: ({ tasks }) => ({
          url: `/tasks`,
          method: 'PUT',
          body: tasks,
        }),
        invalidatesTags: (result, error, args) => providesList(args.tasks, TASK_CACHE_TYPE),
        transformResponse: (response) => response.data,
      }),

      deleteTasks: build.mutation({
        query: ({ tasks }) => ({
          url: `/tasks`,
          method: 'DELETE',
          body: { tasks },
        }),
        invalidatesTags: (result, error, args) =>
          result ? [{ type: TASK_CACHE_TYPE, id: 'LIST' }] : [],
        transformResponse: (response) => response.data,
      }),

      splitTasksByLBS: build.mutation({
        query: ({ type, tasks, locations, zones, areas }) => ({
          url: `/tasks/${type}`,
          body: { tasks, locations, zones, areas },
          method: 'POST',
        }),
        transformResponse: (response) => response.data,
        invalidatesTags: (result, error, args) =>
          result ? providesList(args.tasks, TASK_CACHE_TYPE) : [],
      }),

      insertRecipe: build.mutation({
        query: ({ recipeId, projectId, taskId }) => ({
          url: `recipes/insert`,
          method: 'POST',
          body: { recipe_id: recipeId, task_id: taskId, project_id: projectId },
        }),
        invalidatesTags: (result, error, args) =>
          result ? [{ type: TASK_CACHE_TYPE, id: args.taskId }] : [],
        transformResponse: (response) => normalizeTask(response.data),
      }),

      insertRecipeWithFlowAI: build.mutation({
        query: ({ recipeId, taskId, projectId, locations, zones, areas }) => ({
          url: `recipes/flow`,
          method: 'POST',
          body: {
            recipe_id: recipeId,
            task_id: taskId,
            project_id: projectId,
            locations: locations,
            zone: zones,
            areas: areas,
          },
        }),
        invalidatesTags: (result, error, args) =>
          result ? [{ type: TASK_CACHE_TYPE, id: args.taskId }] : [],
        transformResponse: (response) => {
          const dependencySchema = new schema.Entity('dependencies');
          const taskSchema = new schema.Entity('task');
          taskSchema.define({ children: [taskSchema], dependencies: [dependencySchema] });

          const formattedData = new schema.Array(taskSchema);
          const formattedResponse = normalize(response?.data, formattedData);
          return formattedResponse;
        },
      }),

      importTasks: build.mutation({
        query: ({ projectId, tasks, dependencies }) => ({
          url: `/projects/${projectId}/import`,
          body: { tasks, dependencies },
          method: 'POST',
        }),
        transformResponse: (response) => normalizeTask(response.data),
        invalidatesTags: (result, error, args) =>
          result ? [{ type: TASK_CACHE_TYPE, id: 'LIST' }, { type: DEPENDENCY_TAG, id: 'LIST' }] : [],
      }),

      getOnlineUsers: build.query({
        queryFn: () => ({ data: {} }),
        async onCacheEntryAdded(arg, { updateCachedData, cacheEntryRemoved }) {
          const socket = getSocket();
          const channelNames = arg.channelNames;
          try {
            const handleConnectInitialUser = (users) => {
              const newUsersObj = {};
              users?.forEach((user) => (newUsersObj[user.id] = user));
              updateCachedData((draft) => {
                Object.assign(draft, newUsersObj);
              });
            };

            const handleJoinUser = (user) => {
              updateCachedData((draft) => {
                draft[user.id] = user;
              });
            };

            const handleLeaveUser = (user) => {
              updateCachedData((draft) => {
                delete draft[user.id];
              });
            };

            channelNames.forEach((channelName) => {
              socket
                .join(channelName)
                .here(handleConnectInitialUser)
                .joining(handleJoinUser)
                .leaving(handleLeaveUser);
            });
          } catch (error) {
            console.log('Error while getting online users =>', error);
          }
          await cacheEntryRemoved;
          channelNames?.forEach((channelName) => {
            socket?.leave(channelName);
          });
        },
      }),
      getTasksByArea: build.query({
        query: ({ projectId, areaId } = {}) =>
          `/tasks?project=${projectId}&area=${areaId}&is_production=1&include=company`,
        transformResponse: (response) => response.data,
        providesTags: (result, error, args) =>
          result ? providesList(result, TASK_CACHE_TYPE) : [],
      }),

      getTaskActivityFeeds: build.query({
        query: (queryParam) => {
          const queryString = Object.entries(queryParam)
            .filter(([key, value]) => value !== undefined)
            .map(([key, value]) => `${key}=${value}`)
            .join('&');
          return `/tasks/${queryParam.taskId}/history?${queryString}`;
        },
        providesTags: (result, error) => providesList(result?.data, 'TaskActivity'),
        serializeQueryArgs: ({ endpointName, queryArgs }) => {
          const newQueryArgs = { ...queryArgs };
          if (newQueryArgs.hasOwnProperty('page')) {
            delete newQueryArgs.page;
          }
          return newQueryArgs;
        },
        merge: (currentCache, newItems, queryArgs) => {
          if (queryArgs?.arg?.page) {
            currentCache.data.push(...newItems.data);
          } else {
            currentCache.data = newItems.data;
          }
          currentCache.current_page = newItems.current_page;
          currentCache.last_page = newItems.last_page;
        },
        forceRefetch({ currentArg, previousArg }) {
          return currentArg !== previousArg;
        },
        transformResponse: (response) => {
          return {
            data: response.data,
            current_page: response?.meta?.current_page,
            last_page: response?.meta?.last_page,
          };
        },
      }),

      getProjectTasks: build.query({
        query: ({ projectId, ...queryParam }) => {
          const queryString = Object.entries(queryParam)
            .filter(([key, value]) => value !== undefined)
            .map(([key, value]) => `${key}=${value}`)
            .join('&');
          return `/tasks_tree?project=${projectId}&is_production=0&${queryString}`;
        },
        transformResponse: (response) => {
          const dependencySchema = new schema.Entity('dependencies');
          const taskSchema = new schema.Entity('task');
          taskSchema.define({ children: [taskSchema], dependencies: [dependencySchema] });

          const formattedData = new schema.Array(taskSchema);
          const formattedResponse = normalize(response?.data, formattedData);
          console.log('formattedResponse =>', formattedResponse);
          return { formattedResponse, tasks: response?.data };
        },
        providesTags: (response) => {
          return response ? providesList(response.result, TASK_CACHE_TYPE) : [];
        },
      }),

      updateTask: build.mutation({
        query: ({ task }) => {
          let body = task;
          let method = 'PATCH';
          if (task?.map instanceof File || task?.original_map instanceof File) {
            body = objectToFormData(task);
            body.append('_method', 'PATCH');
            method = 'POST';
          }
          return {
            url: `/tasks/${task?.id}`,
            method,
            body,
          };
        },
        invalidatesTags: (result, error, args) => {
          return result ? [{ type: TASK_CACHE_TYPE, id: args?.task?.id }] : [];
        },
        transformResponse: (response) => response.data,
      }),

      createProductionTask: build.mutation({
        query: ({ task }) => {
          let body = task;
          if (task?.map instanceof File || task?.original_map instanceof File) {
            body = objectToFormData(task);
          }
          return {
            url: `/production/tasks`,
            method: 'POST',
            body,
          };
        },
        invalidatesTags: (result, error, args) =>
          result ? [{ type: TASK_CACHE_TYPE, id: 'LIST' }] : [],
        transformResponse: (response) => response.data,
      }),

      updateProductionTask: build.mutation({
        query: ({ task }) => {
          let body = task;
          let method = 'PATCH';
          if (task?.map instanceof File || task?.original_map instanceof File) {
            body = objectToFormData(task);
            body.append('_method', 'PATCH');
            method = 'POST';
          }
          return {
            url: `/production/tasks/${task?.id}`,
            method,
            body,
          };
        },
        invalidatesTags: (result, error, args) => {
          return result ? [{ type: 'Task', id: args?.task?.id }] : [];
        },
        transformResponse: (response) => response.data,
      }),

      updateTaskTags: build.mutation({
        query: ({ tasks, tags }) => ({
          url: `/tasks/tags`,
          method: 'POST',
          body: { tasks, tags },
        }),
        invalidatesTags: (result, error, args) => {
          return [
            ...providesList(args.tasks, TASK_CACHE_TYPE),
            { type: TAGS_CACHE_KEY, id: 'LIST' },
          ];
        },
        transformResponse: (response) => response.data,
      }),

      syncTagsOnTask: build.mutation({
        query: ({ taskId, tags }) => ({
          url: `/tasks/${taskId}/tags`,
          method: 'POST',
          body: { tags },
        }),
        invalidatesTags: (result, error, args) => {
          return [
            { type: TASK_CACHE_TYPE, id: args.tasksId },
            { type: TAGS_CACHE_KEY, id: 'LIST' },
          ];
        },
        transformResponse: (response) => response.data,
      }),
    }),
  });

export const {
  useCreateTaskMutation,
  useCreateTasksMutation,
  useDeleteTasksMutation,
  useUpdateTaskMutation,
  useUpdateTasksMutation,
  useGetTaskColorsQuery,
  useGetTaskTreeQuery,
  useGetTaskQuery,
  useGetTaskParentsQuery,
  useGetTasksQuery,
  useInsertRecipeMutation,
  useInsertRecipeWithFlowAIMutation,
  useSubscribeTasksQuery,
  useLazySubscribeTasksQuery,
  useImportTasksMutation,
  useGetOnlineUsersQuery,
  useSplitTasksByLBSMutation,
  useGetTasksByAreaQuery,
  useGetProjectTasksQuery,
  useGetTaskActivityFeedsQuery,
  useCreateProductionTaskMutation,
  useUpdateProductionTaskMutation,
  useUpdateTaskTagsMutation,
  useSyncTagsOnTaskMutation,
} = taskAPI;
