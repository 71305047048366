import { ArrowBack, ArrowForward, Check, Close, MenuOpen } from '@mui/icons-material';
import {
  alpha,
  Box,
  Button,
  CircularProgress,
  Fab,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
  Switch,
  Tooltip,
  Divider,
  Typography,
} from '@mui/material';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { Popover } from 'components/Popover';
import { ContentLayout } from 'components/layouts';
import ModalProvider from 'components/common/ModalProvider';
import { useLookaheadGantt } from '../hooks/useLookaheadGantt';
import { LookaheadModals } from '../components/LookaheadModals';
import LookaheadPrintMenu from '../components/LookaheadPrintMenu';
import { addWeeks, isAfter, isBefore, startOfWeek } from 'date-fns';
import { LookaheadTaskDetailsDrawer } from '../components/LookaheadTaskDetailsDrawer';
import { PublishTargetsDrawer } from 'features/targets/components/PublishTargetsDrawer';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
const cascadingTagIcon = `<svg class="MuiSvgIcon-root MuiSvgIcon-fontSizeSmall MuiChip-icon MuiChip-iconSmall MuiChip-iconColorDefault css-i4bv87-MuiSvgIcon-root" style="font-size: .8em" focusable="false" aria-hidden="true" viewBox="0 0 24 24" data-testid="KeyboardDoubleArrowDownOutlinedIcon"><path d="M18 6.41 16.59 5 12 9.58 7.41 5 6 6.41l6 6z"></path><path d="m18 13-1.41-1.41L12 16.17l-4.59-4.58L6 13l6 6z"></path></svg>`;
const smartTagIcon = `<svg class="MuiSvgIcon-root MuiSvgIcon-fontSizeSmall MuiChip-icon MuiChip-iconSmall MuiChip-iconColorDefault css-i4bv87-MuiSvgIcon-root" style="font-size: .8em" focusable="false" aria-hidden="true" viewBox="0 0 24 24" data-testid="TipsAndUpdatesOutlinedIcon"><path d="M7 20h4c0 1.1-.9 2-2 2s-2-.9-2-2m-2-1h8v-2H5zm11.5-9.5c0 3.82-2.66 5.86-3.77 6.5H5.27c-1.11-.64-3.77-2.68-3.77-6.5C1.5 5.36 4.86 2 9 2s7.5 3.36 7.5 7.5m-2 0C14.5 6.47 12.03 4 9 4S3.5 6.47 3.5 9.5c0 2.47 1.49 3.89 2.35 4.5h6.3c.86-.61 2.35-2.03 2.35-4.5m6.87-2.13L20 8l1.37.63L22 10l.63-1.37L24 8l-1.37-.63L22 6zM19 6l.94-2.06L22 3l-2.06-.94L19 0l-.94 2.06L16 3l2.06.94z"></path></svg>`;
const normalTagIcon = `<svg class="MuiSvgIcon-root MuiSvgIcon-fontSizeSmall MuiChip-icon MuiChip-iconSmall MuiChip-iconColorDefault css-i4bv87-MuiSvgIcon-root" style="font-size: .8em" focusable="false" aria-hidden="true" viewBox="0 0 24 24" data-testid="LabelOutlinedIcon"><path d="M17.63 5.84C17.27 5.33 16.67 5 16 5L5 5.01C3.9 5.01 3 5.9 3 7v10c0 1.1.9 1.99 2 1.99L16 19c.67 0 1.27-.33 1.63-.84L22 12zM16 17H5V7h11l3.55 5z"></path></svg>`;
const generatedTagIcon = `<svg class="MuiSvgIcon-root MuiSvgIcon-fontSizeSmall MuiChip-icon MuiChip-iconSmall MuiChip-iconColorDefault css-i4bv87-MuiSvgIcon-root" style="font-size: .8em" focusable="false" aria-hidden="true" viewBox="0 0 24 24" data-testid="AutoAwesomeIcon"><path d="m19 9 1.25-2.75L23 5l-2.75-1.25L19 1l-1.25 2.75L15 5l2.75 1.25zm-7.5.5L9 4 6.5 9.5 1 12l5.5 2.5L9 20l2.5-5.5L17 12zM19 15l-1.25 2.75L15 19l2.75 1.25L19 23l1.25-2.75L23 19l-2.75-1.25z"></path></svg>`;

const startOfThisWeek = startOfWeek(new Date(), { weekStartsOn: 1 });

const generateGradient = (colors) =>
  colors
    ? `linear-gradient(to right, ${colors
        .map((color, index, array) => {
          const start = (index / array.length) * 100;
          const end = ((index + 1) / array.length) * 100;
          return `${color} ${start}% ${end}%`;
        })
        .join(', ')})`
    : undefined;

const filterTags = (tags, type, smartKey) => {
  return tags?.length ? tags?.filter((tag) => tag.type === type && tag.smart_key === smartKey) : [];
};

const generateTagBarHtml = (tags) => {
  return tags?.length
    ? `<div style="display: flex; flex-direction: row; gap: 4px; align-items: center; font-size: 0.8em; line-height: 100%;">
          ${tags
            ?.map(
              (tag) => `
            <div style="display: flex; flex-direction: row; gap: 4px; align-items: center; border-radius: 16px; background-color: ${tag.color}; padding: .1em 4px; ${!tag.color ? 'outline: 1px solid; outline-offset: -1px;' : ''}">
            ${tag.type === 'smart' ? smartTagIcon : tag.type === 'cascading' ? cascadingTagIcon : tag.type === 'generated' ? generatedTagIcon : normalTagIcon}
              ${tag.name}
            </div>
          `
            )
            .join('')}
        </div>`
    : '';
};

const colorByOptions = [
  { label: 'Default', getColor: (task) => task.color },
  {
    label: 'Tag',
    getColor: (task) =>
      task.tags
        ? generateGradient(task.tags.map((tag) => tag.color).filter((color) => color))
        : undefined,
  },
  {
    label: 'Company',
    getColor: (task) =>
      task.companies
        ? generateGradient(task.companies.map((company) => company.color).filter((color) => color))
        : undefined,
  },
];

const displayOptionList = [
  {
    label: 'Location',
    renderTaskListMarkup: (task) => {
      const locationTagName = filterTags(task?.tags, 'generated', 'location')
        ?.map((tag) => tag.name)
        .join('-');
      return locationTagName
        ? `<span class='task-generated-tag'> - ${locationTagName} </span>`
        : '';
    },
    renderTaskBarMarkup: (task) => {
      const locationTags = filterTags(task?.tags, 'generated', 'location');
      return generateTagBarHtml(locationTags);
    },
    isEnabled: true,
  },
  {
    label: 'Phase',
    renderTaskListMarkup: (task) => {
      const phaseTagNames = filterTags(task?.tags, 'generated', 'phase')
        ?.map((tag) => tag.name)
        .join('-');
      return phaseTagNames ? `<span class='task-generated-tag'> - ${phaseTagNames} </span>` : '';
    },
    renderTaskBarMarkup: (task) => {
      const phaseTags = filterTags(task?.tags, 'generated', 'phase');
      return generateTagBarHtml(phaseTags);
    },
    isEnabled: true,
  },
  {
    label: 'Zone',
    renderTaskListMarkup: (task) => {
      const zoneTagNames = filterTags(task?.tags, 'generated', 'zone')
        ?.map((tag) => tag.name)
        .join('-');
      return zoneTagNames ? `<span class='task-generated-tag'> - ${zoneTagNames} </span>` : '';
    },
    renderTaskBarMarkup: (task) => {
      const zoneTags = filterTags(task?.tags, 'generated', 'zone');
      return generateTagBarHtml(zoneTags);
    },
    isEnabled: true,
  },
  {
    label: 'Area',
    renderTaskListMarkup: (task) => {
      const areaTagNames = filterTags(task?.tags, 'generated', 'area')
        ?.map((tag) => tag.name)
        .join('-');
      return areaTagNames ? `<span class='task-generated-tag'> - ${areaTagNames} </span>` : '';
    },
    renderTaskBarMarkup: (task) => {
      const areaTags = filterTags(task?.tags, 'generated', 'area');
      return generateTagBarHtml(areaTags);
    },
    isEnabled: true,
  },
];

const highlightTaskMenuOptions = [
  { label: 'Default', getTaskHighlighter: (task) => task.color },
  {
    label: `Identify tasks without`,
    text: 'Crew Size',
    getTaskHighlighter: (task) => {
      return generateGradient(!task.crew_size ? ['#d32f2f'] : ['#808080']);
    },
  },
  {
    label: 'Identify tasks without',
    text: 'Company',
    getTaskHighlighter: (task) => {
      return generateGradient(!task.company ? ['#d32f2f'] : ['#808080']);
    },
  },
];

const LOOKAHEAD_NO_WEEKS = 4;

export const Lookahead = () => {
  const { taskId: urlTaskId, projectId } = useParams();
  const taskId = urlTaskId || '0';  // Default to '0' if no taskId in URLk
  const [isTargetsOpen, setIsTargetOpen] = useState(false);
  const [targetsDate, setTargetsDate] = useState(startOfThisWeek);
  const [ganttDate, setGanttDate] = useState(addWeeks(startOfThisWeek, -1));
  const [colorBy, setColorBy] = useState(colorByOptions[0]);
  const [highlightTaskBy, setHighlightTaskBy] = useState();
  const [displayOptions, setDisplayOptions] = useState(displayOptionList);

  const {
    ref: ganttRef,
    isLoading: isLoadingGantt,
    gantt,
    portals,
    Gantt,
  } = useLookaheadGantt({
    taskId,
    projectId,
    startDate: ganttDate,
    targetsDate: isTargetsOpen ? targetsDate : undefined,
    colorBy: colorBy?.getColor || highlightTaskBy?.getTaskHighlighter,
    weeks: LOOKAHEAD_NO_WEEKS,
    displayOptions,
  });

  const showToday = () => {
    if (
      isBefore(startOfThisWeek, ganttDate) ||
      isAfter(startOfThisWeek, addWeeks(ganttDate, LOOKAHEAD_NO_WEEKS))
    ) {
      setGanttDate(startOfThisWeek);
    }
    gantt.showDate(new Date());
  };

  const handleToggleDisplay = (selectedOptionIndex, newValue) => {
    const updateDisplayOptions = [...displayOptions];
    updateDisplayOptions[selectedOptionIndex].isEnabled = newValue;
    setDisplayOptions(updateDisplayOptions);
  };

  return (
    <ModalProvider>
      {isLoadingGantt ? (
        <CircularProgress />
      ) : (
        <ContentLayout
          header={
            <Stack alignItems={'center'} borderBottom={'1px solid #c4c4c4'} direction={'row'}>
              <Stack
                alignItems={'center'}
                direction={'row'}
                flex={1}
                gap={1}
                justifyContent={'flex-start'}
              >
                <Popover
                  trigger={
                    <Button endIcon={<ArrowDropDownIcon />} size={'small'}>
                      Color By: {colorBy ? colorBy?.label : 'Default'}
                    </Button>
                  }
                >
                  <List disablePadding>
                    {colorByOptions.map((colorByOption) => (
                      <ListItemButton
                        key={colorByOption?.label}
                        onClick={() => {
                          setHighlightTaskBy(null);
                          setColorBy(colorByOption);
                        }}
                      >
                        <ListItemIcon>{colorByOption === colorBy && <Check />}</ListItemIcon>
                        <ListItemText primary={colorByOption?.label} />
                      </ListItemButton>
                    ))}
                  </List>
                </Popover>
                <Divider flexItem orientation="vertical" variant="middle" />
                <Popover
                  trigger={
                    <Button endIcon={<ArrowDropDownIcon />} size={'small'}>
                      Display
                    </Button>
                  }
                >
                  <List dense disablePadding>
                    {displayOptions?.map((option, index) => {
                      return (
                        <ListItem
                          key={option.label}
                          secondaryAction={
                            <Switch
                              checked={option.isEnabled}
                              color="primary"
                              edge="end"
                              size="small"
                              onClick={(event) => {
                                handleToggleDisplay(index, !option.isEnabled);
                              }}
                            />
                          }
                        >
                          <ListItemText primary={option.label} />
                        </ListItem>
                      );
                    })}
                  </List>
                </Popover>
                <Popover
                  trigger={
                    <Button endIcon={<ArrowDropDownIcon />} size={'small'}>
                      Tools: {highlightTaskBy ? highlightTaskBy?.text : ''}
                    </Button>
                  }
                >
                  <List disablePadding>
                    {highlightTaskMenuOptions?.map((option) => {
                      return (
                        <ListItemButton
                          key={option.label}
                          onClick={() => {
                            setColorBy(null);
                            setHighlightTaskBy(option);
                          }}
                        >
                          <ListItemIcon>{highlightTaskBy === option && <Check />}</ListItemIcon>
                          <ListItemText
                            primary={
                              <>
                                <>
                                  {option.label} <b>{option.text}</b>
                                </>
                              </>
                            }
                          />
                        </ListItemButton>
                      );
                    })}
                  </List>
                </Popover>
              </Stack>
              <Stack alignItems={'center'} direction={'row'} flex={1} justifyContent={'center'}>
                <Stack alignItems={'center'} direction={'row'} spacing={1}>
                  <Button color={'secondary'} size={'small'} onClick={showToday}>
                    Today
                  </Button>
                  <IconButton onClick={() => setGanttDate((prevDate) => addWeeks(prevDate, -1))}>
                    <ArrowBack />
                  </IconButton>
                  <Typography>{ganttDate.toLocaleDateString()}</Typography>
                  <IconButton onClick={() => setGanttDate((prevDate) => addWeeks(prevDate, 1))}>
                    <ArrowForward />
                  </IconButton>
                </Stack>
              </Stack>
              <Stack alignItems={'center'} direction={'row'} flex={1} justifyContent={'flex-end'}>
                <LookaheadPrintMenu
                  gantt={gantt}
                  ganttDate={ganttDate}
                  LOOKAHEAD_NO_WEEKS={LOOKAHEAD_NO_WEEKS}
                  ref={ganttRef}
                />
                <Divider flexItem orientation="vertical" variant="middle" />
                {/* <Tooltip title={`${isTargetsOpen ? 'Close' : 'Open'} Targets`}>
                  <Button
                    color={'primary'}
                    size={'small'}
                    startIcon={<MenuOpen />}
                    onClick={() => setIsTargetOpen((prev) => !prev)}
                  >
                    Targets
                  </Button>
                </Tooltip> */}
              </Stack>
            </Stack>
          }
        >
          <Stack
            direction={'row'}
            height={'100%'}
            overflow={'hidden'}
            position={'relative'}
            width={'100%'}
          >
            <Stack
              flexGrow={1}
              minWidth={0} // This ensures the box can shrink below its content size
            >
              <Box
                flex={1}
                overflow={'hidden'}
                ref={ganttRef}
                sx={{
                  '.gantt_task_content': { justifyContent: 'flex-start' },
                  '.target-range': {
                    pointerEvents: 'none',
                    backgroundColor: (theme) => alpha(theme.palette.secondary.light, 0.3),
                  },
                  '.today': { backgroundColor: (theme) => theme.palette.secondary.main },
                  '.hidden': { backgroundColor: 'transparent' },
                  '.eight-weeks-outs': {
                    backgroundColor: (theme) => alpha(theme.palette.secondary.main, 0.1),
                  },
                  '.seven-weeks-outs': {
                    backgroundColor: (theme) => alpha(theme.palette.secondary.main, 0.2),
                  },
                  '.six-weeks-outs': {
                    backgroundColor: (theme) => alpha(theme.palette.secondary.main, 0.3),
                  },
                  '.five-weeks-outs': {
                    backgroundColor: (theme) => alpha(theme.palette.secondary.main, 0.4),
                  },
                  '.four-weeks-outs': {
                    backgroundColor: (theme) => alpha(theme.palette.secondary.main, 0.5),
                  },
                  '.three-weeks-outs': {
                    backgroundColor: (theme) => alpha(theme.palette.secondary.main, 0.6),
                  },
                  '.two-weeks-outs': {
                    backgroundColor: (theme) => alpha(theme.palette.secondary.main, 0.7),
                  },
                  '.one-weeks-outs': {
                    backgroundColor: (theme) => alpha(theme.palette.secondary.main, 0.8),
                  },
                  '.zero-weeks-outs': {
                    backgroundColor: (theme) => alpha(theme.palette.secondary.main, 0.9),
                  },
                  '.gantt_cell': {
                    fontSize: 16,
                  },

                  '.gantt_task_line.gantt_thin_task .gantt_task_drag.task_end_date': {
                    left: 'unset',
                  },

                  '.gantt_task_line.gantt_bar_task': {
                    transition: 'min-width 0.2s 0s',
                    cursor: 'pointer',
                  },

                  '.gantt_task_line:hover': {
                    minWidth: '30px',
                  },

                  '.gantt_task_drag': {
                    width: '12px',
                    display: 'none',
                  },

                  '.gantt_task_drag:hover': {
                    transition: 'none',
                  },

                  '.gantt_task_line .gantt_task_drag.task_right': {
                    right: '0px !important',
                    background: 'url(/assets/images/drag-indicator.svg) center',
                    objectFit: 'contain',
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: '15px 15px',
                  },

                  '.gantt_task_line .gantt_task_drag.task_left': {
                    left: '0px !important',
                    background: 'url(/assets/images/drag-indicator.svg) center',
                    borderRadius: '12px',
                    objectFit: 'contain',
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: '15px 15px',
                  },

                  '.gantt_task_line:not(.gantt_milestone):hover .gantt_task_drag': {
                    display: 'block',
                    opacity: '1 !important',
                  },
                }}
              >
                {portals}
              </Box>
              <LookaheadModals />
              <LookaheadTaskDetailsDrawer gantt={gantt} />
            </Stack>

            <Box position="relative" sx={{ flexShrink: 0 }}>
              <PublishTargetsDrawer
                date={targetsDate}
                handleClose={() => setIsTargetOpen(false)}
                isOpen={isTargetsOpen}
                projectId={projectId}
                setDate={setTargetsDate}
                taskId={taskId}
              >
                {/* <Fab
                  color={isTargetsOpen ? 'default' : 'primary'}
                  size="small"
                  variant="contained"
                  sx={{
                    position: 'absolute',
                    left: isTargetsOpen ? '-20px' : '-40px',
                    top: '50%',
                    transform: 'translateY(-50%)',
                  }}
                  onClick={() => setIsTargetOpen((prev) => !prev)}
                >
                  {isTargetsOpen ? <Close /> : <MenuOpen />}
                </Fab> */}
              </PublishTargetsDrawer>
            </Box>
          </Stack>
        </ContentLayout>
      )}
    </ModalProvider>
  );
};

export default Lookahead;
