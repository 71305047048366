import { AddBox, Delete, VerticalAlignBottom } from '@mui/icons-material';
import { Box, IconButton } from '@mui/material';
import { useModal } from 'components/common/ModalProvider';
import { insertChildTask, insertTaskBelow } from 'features/tasksGantt/utils/taskActions/insertTask';
import { LOOKAHEAD_MODALS } from './LookaheadModals';

export const LookaheadActionCell = ({ task, gantt }) => {
  const { openModal } = useModal();
  return (
    <Box backgroundColor={'white'}>
      <IconButton
        color={'error'}
        size={'small'}
        onClick={() =>
          openModal(LOOKAHEAD_MODALS.DELETE, {
            handleDelete: () => gantt.deleteTask(task.id),
            item: 'task',
            itemNames: [task.text],
          })
        }
      >
        <Delete />
      </IconButton>
      <IconButton color={'primary'} size={'small'} onClick={() => insertChildTask(task.id, gantt)}>
        <VerticalAlignBottom />
      </IconButton>
      <IconButton color={'primary'} size={'small'} onClick={() => insertTaskBelow(task.id, gantt)}>
        <AddBox />
      </IconButton>
    </Box>
  );
};
