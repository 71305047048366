function postOrderTraversal(gantt) {
  let traversal = [];

  function visit(id) {
    if (!id) return;

    const children = gantt.getChildren(id);
    for (let child of children) {
      visit(child);
    }
    traversal.push(id);
  }

  let parents = [];
  gantt.eachTask((t) => {
    if (!t.parent) {
      parents.push(t);
    }
  });

  parents.forEach((p) => visit(p.id));
  return traversal;
}

module.exports = { postOrderTraversal };
