import axios from 'axios';
import keys from 'lodash/keys';
import times from 'lodash/times';
import sortBy from 'lodash/sortBy';
import isArray from 'lodash/isArray';
import isEqual from 'lodash/isEqual';
import { saveAs } from 'file-saver';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import { PDFDocument } from 'pdf-lib';
import { memo, useState, } from 'react';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import { Document, Page } from 'react-pdf';
import isEqualWith from 'lodash/isEqualWith';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import LoadingButton from '@mui/lab/LoadingButton';
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import FormControlLabel from '@mui/material/FormControlLabel';
import CardActionArea from '@mui/material/CardActionArea';
import { extractChunkNo } from '../../../ganttConfig/printHelper';


const customizer = (objValue, othValue) => {
  if (isArray(objValue) && isArray(othValue)) {
    return isEqual(sortBy(objValue), sortBy(othValue));
  }
  return undefined;
};

/**
 * Preview PDF
 * @param {String} url
 * @param {Array} chunks
 * @param {String} pdfName
 */
const PreviewPDF = memo(({ url, chunks, pdfName }) => {

  const [displayGrid, setDisplayGrid] = useState(true);
  const [totalPages, setTotalPages] = useState(undefined);
  const [loading, setLoading] = useState(false);
  const [selectedPages, setSelectedPages] = useState(undefined);

  const handleToggleView = () => {
    const isDisplayingGridView = !displayGrid;
    setDisplayGrid(isDisplayingGridView);
    !isDisplayingGridView && setSelectedPages(undefined)
  }

  const handleSelectAll = (event) => {
    event.target.checked ? setSelectedPages(totalPages) : setSelectedPages(undefined)

  };

  const handleDownload = async () => {
    if (!selectedPages) {
      window.open(url, '_blank')
      return;
    }
    setLoading(true)
    const sortedKeys = keys(selectedPages).sort((a, b) =>
      extractChunkNo(a) - extractChunkNo(b)
    );

    const newPdfDoc = await PDFDocument.create();
    for (const fileUrl of sortedKeys) {
      if (selectedPages[fileUrl].length) {
        const response = await axios.get(fileUrl, { responseType: 'arraybuffer' });
        const existingPdfBytes = response.data;
        const pdfDoc = await PDFDocument.load(existingPdfBytes);
        const pages = await newPdfDoc.copyPages(pdfDoc, selectedPages[fileUrl].map((page) => page - 1));
        pages.forEach(page => newPdfDoc.addPage(page));
      }
    }
    const pdfBytes = await newPdfDoc.save();
    const blob = new Blob([pdfBytes], { type: 'application/pdf' });
    saveAs(blob, `${pdfName}.pdf`);
    setLoading(false);
  };

  return <Grid container spacing={1} sx={{ p: 1 }}>
    <Grid container item alignItems={'center'} xs={12}>
      <Grid container item xs gap={2}>
        <Button variant='outlined' onClick={handleToggleView}>{displayGrid ? 'Show Single View' : 'Show Flow View'} </Button>
        <LoadingButton loading={loading} variant='contained' onClick={handleDownload}>Download {selectedPages && keys(selectedPages).length ? 'Selected' : 'All'} Pages</LoadingButton>
      </Grid>
      {displayGrid ?
        <Grid container item xs justifyContent={'flex-end'}>
          <FormControlLabel control={<Checkbox checked={isEqualWith(totalPages, selectedPages, customizer)} onChange={handleSelectAll} />} label="Select All" />
        </Grid> : null}
    </Grid>
    {displayGrid ?
      <Grid container item xs={12} >
        <Grid container item direction={'column'} gap={1} sx={{ height: 550, margin: 'auto', overflow: 'auto', p: 1 }} xs={12}>
          {chunks?.map((url) => {
            return <DisplayPDFAndPages
              key={url}
              selectedPages={selectedPages ? selectedPages[url] : undefined}
              setSelectedPages={setSelectedPages}
              setTotalPages={setTotalPages}
              url={url}
            />
          })}
        </Grid>
      </Grid>
      : <Grid container item xs={12} >
        <DisplaySingleView url={url} />
      </Grid>}
  </Grid>

})


/**
 * Display all pdf pages
 * @param {String} url 
 * @returns 
 */
const DisplayPDFAndPages = memo(({ url, setSelectedPages, selectedPages, setTotalPages }) => {

  const [numPages, setNumPages] = useState(undefined)

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages)
    const pages = Array.from({ length: numPages }, (_, index) => index + 1)
    setTotalPages((prev) => ({
      ...prev,
      [url]: pages
    }))
    setSelectedPages((prev) => ({
      ...prev,
      [url]: pages
    }))
  }

  const handleSelectPageToggle = (pageNumber) => {
    setSelectedPages((prevPages) => {
      if (!prevPages) {
        return { [url]: [pageNumber] }
      }
      if (!prevPages[url]) {
        return { ...prevPages, [url]: [pageNumber] };
      }
      if (prevPages[url].includes(pageNumber)) {
        return {
          ...prevPages,
          [url]: prevPages[url].filter((page) => page !== pageNumber),
        };
      }
      return { ...prevPages, [url]: [...prevPages[url], pageNumber] };
    });
  }


  return <Grid item xs>
    <Document file={url} onLoadSuccess={onDocumentLoadSuccess}>
      <Grid container item alignItems={'center'} direction={'column'} gap={2} xs={12}>
        {times(numPages, (index) => {
          const pageNumber = index + 1
          const isSelected = selectedPages?.includes(pageNumber)
          return <Grid
            container
            item
            alignItems={'center'}
            component={Card}
            justifyContent={'center'}
            key={index}
            sx={{ opacity: isSelected ? 1 : 0.4, border: `1px solid ${isSelected ? 'black' : 'lightGray'}`, p: 1 }}
            xs="auto"
            onClick={() => handleSelectPageToggle(pageNumber)}
          >
            <CardActionArea sx={{ position: 'relative' }}>
              <Checkbox checked={isSelected} sx={{ position: "absolute", zIndex: 99 }} />
              <Page height={200} key={`page_${pageNumber}`} pageNumber={pageNumber} renderAnnotationLayer={false} renderTextLayer={false} scale={2} />
            </CardActionArea>
          </Grid>
        })}
      </Grid>
    </Document>
  </Grid>
})


/**
 * Single View
 * @param {String} url 
 * @returns 
 */
const DisplaySingleView = ({ url, fitToPrint }) => {

  const [pageNumber, setPageNumber] = useState(1);
  const [numPages, setNumPages] = useState();

  const onDocumentLoadSuccess = (data) => {
    setNumPages(data.numPages);
  }

  const onDocumentLoadError = (error) => {
    console.log('error =>', error)
  }


  return <>
    <Grid container item alignItems={fitToPrint ? 'start' : 'center'} justifyContent={'center'} sx={{ height: 500, margin: 'auto', overflowY: 'auto' }} xs={12}>
      <Document file={url} onLoadError={onDocumentLoadError} onLoadSuccess={onDocumentLoadSuccess}>
        <Page key={`page_${pageNumber}`} pageNumber={pageNumber} renderAnnotationLayer={false} renderTextLayer={false} scale={3} width={150} />
      </Document>
    </Grid>
    <Grid container item alignItems={'center'} component={Paper} elevation={2} sx={{ px: 1 }} xs={12}>
      {numPages &&
        <>
          <IconButton
            disabled={pageNumber <= 1}
            onClick={() => { setPageNumber(prevPageNumber => prevPageNumber - 1) }}
          >
            <ArrowLeftIcon fontSize="inherit" />
          </IconButton>
          <IconButton
            disabled={pageNumber === numPages}
            onClick={() => { setPageNumber(prevPageNumber => prevPageNumber + 1) }}
          >
            <ArrowRightIcon fontSize="inherit" />
          </IconButton>
          <Typography sx={{ marginLeft: 'auto', size: 14 }} >Page {pageNumber} of {numPages} </Typography>

        </>}
    </Grid>
  </>

}


export default PreviewPDF