import React, { useEffect, useMemo, useState } from 'react';
import {
  Box,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Popover,
  Select,
  Typography,
} from '@mui/material';
import { FilterList } from '@mui/icons-material';
import { useGetProjectGeneratedTagsQuery } from '../../tags/store/tag.api';

const AnalyticsTagFilter = ({ projectId, onFilterChange }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedSmartKey, setSelectedSmartKey] = useState('');
  const [selectedTag, setSelectedTag] = useState('');

  const open = Boolean(anchorEl);

  const { data: tags = [] } = useGetProjectGeneratedTagsQuery(projectId);

  const smartKeys = useMemo(() => {
    const keys = new Set(tags.map((tag) => tag.smart_key));
    return Array.from(keys).sort();
  }, [tags]);

  const availableTags = useMemo(() => {
    if (!selectedSmartKey) return [];
    return tags
      .filter((tag) => tag.smart_key === selectedSmartKey)
      .sort((a, b) => a.name.localeCompare(b.name));
  }, [selectedSmartKey, tags]);

  const selectedTagName = useMemo(() => {
    if (!selectedTag) return '';
    const tag = tags.find((t) => t.id === selectedTag);
    return tag?.name || '';
  }, [selectedTag, tags]);

  useEffect(() => {
    if (!selectedSmartKey) {
      setSelectedTag('');
    }
  }, [selectedSmartKey]);

  useEffect(() => {
    onFilterChange?.(selectedTag || undefined);
  }, [selectedTag, onFilterChange]);

  const handleFilterClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleFilterClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
      <Typography sx={{ color: 'text.secondary' }} variant="body2">
        {selectedTag
          ? `${selectedSmartKey.charAt(0).toUpperCase() + selectedSmartKey.slice(1)}: ${selectedTagName}`
          : 'Filter By Tag'}
      </Typography>

      <Box sx={{ alignSelf: 'flex-end' }}>
        <IconButton
          size="small"
          sx={{
            backgroundColor: selectedTag ? 'primary.main' : open ? '#f3f4f6' : 'transparent',
            color: selectedTag ? 'white' : 'inherit',
            '&:hover': {
              backgroundColor: selectedTag ? 'primary.dark' : '#f3f4f6',
            },
          }}
          onClick={handleFilterClick}
        >
          <FilterList fontSize="small" />
        </IconButton>
      </Box>

      <Popover
        anchorEl={anchorEl}
        open={open}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        onClose={handleFilterClose}
      >
        <Box sx={{ p: 3, display: 'flex', gap: 2, width: '400px' }}>
          <FormControl size="small" sx={{ flex: 1 }}>
            <InputLabel size="small">Filter Type</InputLabel>
            <Select
              label="Filter Type"
              value={selectedSmartKey}
              onChange={(e) => setSelectedSmartKey(e.target.value)}
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              {smartKeys.map((key) => (
                <MenuItem key={key} value={key}>
                  {key.charAt(0).toUpperCase() + key.slice(1)}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl disabled={!selectedSmartKey} size="small" sx={{ flex: 1 }}>
            <InputLabel size="small">{selectedSmartKey || 'Select Type First'}</InputLabel>
            <Select
              label={selectedSmartKey || 'Select Type First'}
              value={selectedTag}
              onChange={(e) => setSelectedTag(e.target.value)}
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              {availableTags.map((tag) => (
                <MenuItem key={tag.id} value={tag.id}>
                  {tag.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
      </Popover>
    </Box>
  );
};

export default AnalyticsTagFilter;
