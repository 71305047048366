import ganttStore from 'components/projectOverview/gantt/ganttConfig/ganttStore';
import {
  createRecipeTask,
  deleteRecipeTasks,
  updateRecipeTasks,
} from 'features/recipes/store/recipeTasksSlice';
import {
  addRecipeDependency,
  deleteRecipeDependencies,
  updateRecipeDependencies,
} from 'features/recipes/store/recipeDependenciesSlice';
import store from '../../../store';
import { formatRecipePayload } from './RecipeGanttFormatter';

let dataProcessor = undefined;
let autoUpdateTimer = 0;
const pendingUpdates = {
  updateRecipes: {},
  deleteRecipes: [],
  updateRecipeLinks: {},
  deleteRecipeLinks: [],
};

export const initRecipeGanttDataProcessor = (recipeId, gantt) => {
  dataProcessor = gantt.createDataProcessor({
    task: {
      create: async (data) => {
        return store
          .dispatch(createRecipeTask({ ...formatRecipePayload(data), recipeId }))
          .then((apiResponse) => {
            return apiResponse.payload?.data;
          });
      },
      update: (data, id) => {
        if (id !== -1) {
          updateRecipe(data);
          autoUpdate(recipeId);
        }
      },
      delete: (id) => {
        deleteRecipe(id);
        autoUpdate(recipeId);
      },
    },
    link: {
      create: async (data) => {
        if (!data.isCreated) {
          return store
            .dispatch(
              addRecipeDependency({
                recipeId: recipeId,
                source: data.source,
                target: data.target,
                type: data.type,
                lag: data.lag,
              })
            )
            .then((apiResponse) => {
              autoUpdate(recipeId);
              return apiResponse.payload.data;
            });
        }
      },
      update: (data, id) => {
        updateRecipeLink(data);
        autoUpdate(recipeId);
      },
      delete: (id) => {
        deleteRecipeLink(id);
        autoUpdate(recipeId);
      },
    },
  });

  dataProcessor.attachEvent('onBeforeUpdate', function (id, state, data) {
    //Need to check if placeholder
    if (Object.keys(data).length <= 2 || data.type === gantt.config.types.placeholder) {
      return false;
    } else {
      return true;
    }
  });

  dataProcessor.attachEvent('onAfterUpdate', function (tempId, action, tid, response) {
    // const response = responseData?.response?.data;
    switch (action) {
      case 'inserted': {
        if (response && response.object_type == 'recipe_task' && tempId !== response.id) {
          gantt.changeTaskId(tempId, response.id);
          gantt.getTask(response.id).uuid = response.uuid;
          gantt.getTask(response.id).recipe_id = response.recipe_id;
          gantt.showTask(response.id);
        }
        if (response && response.object_type == 'dependency' && tempId !== response.id) {
          if (gantt.isLinkExists(tempId)) {
            gantt.changeLinkId(tempId, response.id);
            gantt.refreshLink(response.id);
          }
        }
        break;
      }
      case 'error':
        break;
      default:
        break;
    }
  });
};

export const updateRecipe = (task) => {
  pendingUpdates.updateRecipes[task.id] = formatRecipePayload(task);
};

export const deleteRecipe = (id) => {
  pendingUpdates.deleteRecipes.push(id);
};

export const updateRecipeLink = (link) => {
  pendingUpdates.updateRecipeLinks[link.id] = link;
};

export const deleteRecipeLink = (id) => {
  pendingUpdates.deleteRecipeLinks.push(id);
};

const autoUpdate = (recipeId) => {
  clearTimeout(autoUpdateTimer);
  autoUpdateTimer = setTimeout(async () => {
    processPendingUpdates(recipeId);
  }, 500);
};

export const removeDataProcessor = () => {
  if (dataProcessor) {
    dataProcessor.destructor();
    dataProcessor = null;
    clearTimeout(autoUpdateTimer);
    processPendingUpdates();
  }
};

const processPendingUpdates = async (recipeId) => {
  if (pendingUpdates.updateRecipes && Object.values(pendingUpdates.updateRecipes)?.length) {
    store
      .dispatch(
        updateRecipeTasks({
          recipeId: recipeId,
          recipes: Object.values(pendingUpdates.updateRecipes),
        })
      )
      .then(() => {
        pendingUpdates.updateRecipes = {};
      });
  }

  if (pendingUpdates.deleteRecipes && pendingUpdates.deleteRecipes.length) {
    store.dispatch(deleteRecipeTasks({ ids: pendingUpdates.deleteRecipes, recipeId })).then(() => {
      pendingUpdates.deleteRecipes = [];
    });
  }

  if (pendingUpdates.updateRecipeLinks && Object.values(pendingUpdates.updateRecipeLinks)?.length) {
    store
      .dispatch(
        updateRecipeDependencies({
          recipeId: recipeId,
          dependencies: Object.values(pendingUpdates.updateRecipeLinks),
        })
      )
      .then(() => {
        pendingUpdates.updateRecipeLinks = {};
      });
  }

  if (pendingUpdates.deleteRecipeLinks && pendingUpdates.deleteRecipeLinks.length) {
    store
      .dispatch(
        deleteRecipeDependencies({
          recipeId: recipeId,
          dependencies: pendingUpdates.deleteRecipeLinks,
        })
      )
      .then(() => {
        pendingUpdates.deleteRecipeLinks = [];
      });
  }
};
