import { addDays } from 'date-fns';

const today = new Date();

const createNewTask = (startDate = today, endDate = today, overrides = {}) => ({
  text: 'Create New Task',
  start_date: startDate,
  end_date: endDate,
  scheduled_end_date: endDate,
  forecasted_start_date: startDate,
  forecasted_end_date: endDate,
  cal_days: 1,
  work_days: 1,
  status: 'todo',
  type: 'task',
  autoschedule_date: 'schedule',
  $new: true,
  tags: [],
  ...overrides,
});

export const insertChildTask = (taskId, gantt) => {
  try {
    const task = gantt.getTask(taskId);
    task.$open = true;
    const newTaskId = gantt.addTask(
      createNewTask(task.start_date, task.end_date, {
        location_id: task.location_id,
        zone_id: task.zone_id,
        area_id: task.area_id,
        project_id: task.project_id,
        $display_order: task.$display_order + 1,
        target: `next:${task.id}`,
      }),
      task.id
    );
    gantt.showTask(newTaskId);
    gantt.selectTask(newTaskId);
  } catch (error) {
    console.error('Error inserting child task:', error);
  }
};

export const insertTaskAbove = (taskId, gantt) => {
  try {
    const task = gantt.getTask(taskId);
    const isParent = gantt.isTaskExists(task.parent);
    const parent = isParent ? gantt.getTask(task.parent) : null;
    const endDate = gantt.getClosestWorkTime({
      date: addDays(task.start_date, 1),
      direction: 'future',
    });

    const newTaskId = gantt.addTask(
      createNewTask(task.start_date, endDate, {
        location_id: parent?.location_id,
        zone_id: parent?.zone_id,
        area_id: parent?.area_id,
        project_id: task?.project_id,
        $display_order: task?.$display_order,
        target: `${task.id}`,
      }),
      isParent ? task?.parent : null,
      gantt.getTaskIndex(taskId)
    );
    gantt.showTask(newTaskId);
    gantt.selectTask(newTaskId);
  } catch (error) {
    console.error('Error inserting task above:', error);
  }
};

export const insertTaskBelow = (taskId, gantt) => {
  try {
    const task = gantt.getTask(taskId);
    const indexToInsert = gantt.getTaskIndex(taskId) + 1;
    const target = gantt.getTaskByIndex(gantt.getTask(taskId)?.$index + 1);
    const isParent = gantt.isTaskExists(task.parent);
    const parent = isParent ? gantt.getTask(task.parent) : null;

    const endDate = gantt.getClosestWorkTime({
      date: addDays(task.start_date, 1),
      direction: 'future',
    });
    const newTaskId = gantt.addTask(
      createNewTask(task.start_date, endDate, {
        location_id: parent?.location_id,
        zone_id: parent?.zone_id,
        area_id: parent?.area_id,
        project_id: task?.project_id,
        $display_order: task?.$display_order + 1,
        target: target && target.type !== 'placeholder' ? `next:${task.id}` : null,
      }),
      isParent ? task.parent : null,
      indexToInsert
    );
    gantt.showTask(newTaskId);
    gantt.selectTask(newTaskId);
  } catch (error) {
    console.error('Error inserting task below:', error);
  }
};
