import { GanttStatic } from '@blackhyve/dhtmlx-gantt';
import { DemandCalculation } from '../api/resources.models';
import { postOrderTraversal } from '@blackhyve/utilities/gantt/postOrderTraversal';

export function updateGanttDemand(gantt: GanttStatic) {
  const traversal = postOrderTraversal(gantt);

  let changed = new Set<number | string>();
  traversal.forEach((id) => {
    let task = gantt.getTask(id);
    if (task.type === gantt.config.types.placeholder) {
      return;
    }
    let currentDemand = +task.demand;
    let sumOfChildren = 0;

    const children = gantt.getChildren(id).map((c) => gantt.getTask(c));
    if (children.length) {
      sumOfChildren = children.reduce((total, c) => {
        const demand = +c.demand || 0;
        return total + demand;
      }, 0);
    }

    let demandCalculation = task.demand_calculation;

    if (task.$demand !== sumOfChildren) {
      switch (demandCalculation) {
        case DemandCalculation.Max: {
          task.demand = currentDemand;
          task.$demand = sumOfChildren;
          changed.add(id);
          break;
        }

        case DemandCalculation.Sum: {
          task.demand = sumOfChildren;
          task.$demand = sumOfChildren;
          changed.add(id);
          break;
        }

        default:
          break;
      }
    }
    if ((task.optimal_crew === -1 || task.optimal_crew === 1) && task.work_days && task.demand) {
      const demand = task.demand;
      const newDemandPerDay = Math.ceil(demand / task.work_days);
      const newCrewSize = newDemandPerDay ? Math.ceil(newDemandPerDay / 8) : 0;
      if (task.crew_size !== newCrewSize) {
        task.crew_size = newCrewSize;
        task.optimal_crew = 1;
        changed.add(id);
      }
    }
  });

  gantt.batchUpdate(() => {
    changed.forEach((c) => gantt.updateTask(c));
  });
}
