import { updateForecastedDates } from '@blackhyve/utilities/gantt';
import { getChildren } from '@blackhyve/utilities/tasks';

export function pasteTaskClipboard({
  clipBoard: { tasks = [], links = [] } = {},
  parentTask,
  index = 0,
  gantt,
}) {
  const display_order = parentTask.$display_order + 1;
  const taskIds = new Set(tasks.map((task) => task.id));

  tasks.sort((a, b) => a.$display_order - b.$display_order);

  let newTaskIndex = index;
  gantt._block_auto_schedule = true;
  gantt.batchUpdate(() => {
    tasks.forEach((task, currentIndex) => {
      const newTask = {
        ...task,
        id: 'paste-' + task.id,
        $display_order: display_order + currentIndex,
        // index: taskIds.has(task.parent) ? 0 : newTaskIndex++,
        parent: taskIds.has(task.parent) ? 'paste-' + task.parent : parentTask.id,
        $imported_parent_id: taskIds.has(task.parent) ? 'paste-' + task.parent : undefined,

        progress: 0,
        status: 'todo',
        pr: null,
        pace: 0,

        forecasted_start_date: task.start_date,
        forecasted_end_date: task.scheduled_end_date,
        end_date: task.scheduled_end_date,
        actual_start_date: null,
        actual_end_date: null,
        earned_days: null,
        earned_planned_days: null,

        project_id: parentTask.project_id,

        location_id: task.project_id === parentTask.project_id ? task.location_id : undefined,
        zone_id: task.project_id === parentTask.project_id ? task.zone_id : undefined,
        area_id: task.project_id === parentTask.project_id ? task.area_id : undefined,

        companies: task.project_id === parentTask.project_id ? task.companies : [],
        contacts: task.project_id === parentTask.project_id ? task.contacts : [],
        responsible_users: task.project_id === parentTask.project_id ? task.responsible_users : [],

        target: `next:paste-${currentIndex === 0 ? parentTask.id : tasks[currentIndex - 1].id}`,
      };

      if (task.project_id !== parentTask.project_id) {
        delete newTask.location_id;
        delete newTask.zone_id;
        delete newTask.area_id;
      }

      Object.entries(newTask).forEach(([key, value]) => {
        if (value && key.includes('date') && key !== 'autoschedule_date') {
          const oldValue = value;
          newTask[key] = new Date(oldValue);
        }
      });

      gantt.addTask(
        newTask,
        newTask.parent,
        taskIds.has(task.parent) ? getChildren(newTask.parent, gantt).length : newTaskIndex++
      );
    });

    links.forEach((link) =>
      gantt.addLink({
        ...link,
        id: 'paste-' + link.id,
        target: 'paste-' + link.target,
        source: 'paste-' + link.source,
        project_id: parentTask.project_id,
      })
    );
    gantt._block_auto_schedule = false;
  });
  updateForecastedDates(undefined, gantt);
  gantt.autoSchedule(parentTask.id);
}
