import { StyledDialog } from '@blackhyve/common';
import { parseDate } from '@blackhyve/utilities/dates';
import { Alert, Box, Button, CircularProgress, Tab, Tabs } from '@mui/material';
import { formatDate } from 'date-fns';
import { useCanEditProject } from 'hooks/useCanEditProject';
import { useCallback, useMemo, useState } from 'react';
import {
  useCreateProjectMutation,
  useGetProjectQuery,
  useUpdateProjectMutation,
} from '../store/project.api';
import { BasicInfoForm } from './ProjectSettingsForm/BasicInfoForm';
import { FeaturesForm } from './ProjectSettingsForm/FeaturesForm';
import { LocationForm } from './ProjectSettingsForm/LocationForm';
import { NotificationsForm } from './ProjectSettingsForm/NotificationsForm';
import { ProjectDetailsForm } from './ProjectSettingsForm/ProjectDetailsForm';
import { ScheduleForm } from './ProjectSettingsForm/ScheduleForm';

const TABS = {
  BASIC_INFO: 'basicInfo',
  PROJECT_DETAILS: 'projectDetails',
  LOCATION: 'location',
  SCHEDULE: 'schedule',
  FEATURES: 'features',
  NOTIFICATIONS: 'notifications',
};

export const ProjectSettingsDialog = ({ projectId, open, handleClose }) => {
  const [currentTab, setCurrentTab] = useState(TABS.BASIC_INFO);
  const [combinedFormData, setCombinedFormData] = useState({});
  const isCreate = !projectId;
  const canEdit = useCanEditProject(projectId);

  const [createProject, { isLoading: isCreating, isError: isErrorCreating, error: createError }] =
    useCreateProjectMutation();
  const [updateProject, { isLoading: isUpdating, isError: isErrorUpdating, error: updateError }] =
    useUpdateProjectMutation();

  const { data: project, isLoading: isLoadingProject } = useGetProjectQuery(projectId, {
    skip: !projectId,
  });

  // Set initial form data when project details are loaded
  const initialValues = useMemo(() => {
    if (!isCreate && project) {
      // Parse and organize the data for each tab
      const basicInfo = {
        name: project.name,
        status: project.status,
        description: project.description,
        start_date: project.start_date ? parseDate(project.start_date) : null,
        sub_completion_date: project.sub_completion_date
          ? parseDate(project.sub_completion_date)
          : null,
        color: project.color,
      };

      const projectDetails = {
        project_manager: project.project_manager,
        project_number: project.project_number,
        client: project.client,
        project_dimension_1: project.project_dimension_1,
        project_dimension_2: project.project_dimension_2,
        project_dimension_3: project.project_dimension_3,
      };

      const location = {
        address: project.address,
        city: project.city,
        state: project.state,
        zip_code: project.zip_code,
      };

      const schedule = {
        workday_calendar: project.workday_calendar,
      };

      const features = {
        check_task_dependencies: Boolean(project.check_task_dependencies),
        budgets_feature: Boolean(project.budgets_feature),
        production_schedule: Boolean(project.production_schedule),
        undo_feature: Boolean(project.undo_feature),
      };

      const notifications = {
        notification_frequency: project.notification_frequency,
      };

      return {
        basicInfo,
        projectDetails,
        location,
        schedule,
        features,
        notifications,
      };
    }
  }, [isCreate, project]);

  // Handle individual tab form submissions
  const handleTabSubmit = useCallback((tabName, data) => {
    setCombinedFormData((prev) => ({
      ...prev,
      [tabName]: data,
    }));
  }, []);

  // Final form submission
  const handleSave = async () => {
    const finalData = {
      id: projectId,
      ...combinedFormData.basicInfo,
      ...combinedFormData.projectDetails,
      ...combinedFormData.location,
      ...combinedFormData.schedule,
      ...combinedFormData.features,
      ...combinedFormData.notifications,
    };

    if (combinedFormData?.basicInfo?.start_date) {
      finalData.start_date = formatDate(combinedFormData.basicInfo.start_date, 'yyyy-MM-dd');
    }
    if (combinedFormData?.basicInfo?.sub_completion_date) {
      finalData.sub_completion_date = formatDate(
        combinedFormData.basicInfo.sub_completion_date,
        'yyyy-MM-dd'
      );
    }

    try {
      if (isCreate) {
        await createProject(finalData).unwrap();
      } else {
        await updateProject(finalData).unwrap();
      }
      handleClose();
    } catch (error) {
      // Handle error appropriately
      console.error('Error saving project:', error);
    }
  };

  const isLoading = isCreating || isUpdating || isLoadingProject;

  const isError = isErrorCreating || isErrorUpdating;

  return (
    <StyledDialog
      handleClose={handleClose}
      maxWidth={'md'}
      open={open}
      title={isCreate ? 'New Project' : 'Project Settings'}
      actions={
        <>
          <Button size="small" onClick={handleClose}>
            Cancel
          </Button>
          <Button disabled={!canEdit} size="small" variant="contained" onClick={handleSave}>
            {isCreate ? 'Create' : 'Save'}
          </Button>
        </>
      }
    >
      {isLoading ? (
        <CircularProgress className="loading" />
      ) : (
        <>
          {isError && (
            <Alert severity={'error'}>
              {updateError?.data?.message}
              {createError?.data?.message}
            </Alert>
          )}
          <Box sx={{ borderBottom: 1, borderColor: 'divider', mb: 2 }}>
            <Tabs
              aria-label="project settings tabs"
              scrollButtons="auto"
              value={currentTab}
              variant="scrollable"
              onChange={(_, newValue) => setCurrentTab(newValue)}
            >
              <Tab label="Basic Info" value={TABS.BASIC_INFO} />
              <Tab label="Project Details" value={TABS.PROJECT_DETAILS} />
              <Tab label="Location" value={TABS.LOCATION} />
              <Tab label="Schedule" value={TABS.SCHEDULE} />
              <Tab label="Features" value={TABS.FEATURES} />
              <Tab label="Notifications" value={TABS.NOTIFICATIONS} />
            </Tabs>
          </Box>

          {TABS.BASIC_INFO === currentTab && (
            <BasicInfoForm
              canEdit={canEdit}
              initialData={initialValues?.basicInfo}
              onSubmit={(data) => handleTabSubmit(TABS.BASIC_INFO, data)}
            />
          )}
          {TABS.PROJECT_DETAILS === currentTab && (
            <ProjectDetailsForm
              canEdit={canEdit}
              initialData={initialValues?.projectDetails}
              projectId={projectId}
              onSubmit={(data) => handleTabSubmit(TABS.PROJECT_DETAILS, data)}
            />
          )}
          {TABS.LOCATION === currentTab && (
            <LocationForm
              canEdit={canEdit}
              initialData={initialValues?.location}
              onSubmit={(data) => handleTabSubmit(TABS.LOCATION, data)}
            />
          )}
          {TABS.SCHEDULE === currentTab && (
            <ScheduleForm
              canEdit={canEdit}
              initialData={initialValues?.schedule}
              onSubmit={(data) => handleTabSubmit(TABS.SCHEDULE, data)}
            />
          )}
          {TABS.FEATURES === currentTab && (
            <FeaturesForm
              canEdit={canEdit}
              initialData={initialValues?.features}
              onSubmit={(data) => handleTabSubmit(TABS.FEATURES, data)}
            />
          )}
          {TABS.NOTIFICATIONS === currentTab && (
            <NotificationsForm
              canEdit={canEdit}
              initialData={initialValues?.notifications}
              onSubmit={(data) => handleTabSubmit(TABS.NOTIFICATIONS, data)}
            />
          )}
        </>
      )}
    </StyledDialog>
  );
};
